const firebaseConfig = {
    PROD: {
        apiKey: "AIzaSyBqTSLbJxSjxLNWggKj6UNoNxBxgLlXPeo",
        authDomain: "busstop-68aac.firebaseapp.com",
        databaseURL: "https://busstop-68aac.firebaseio.com",
        projectId: "busstop-68aac",
        storageBucket: "busstop-68aac.appspot.com",
        messagingSenderId: "98251789607",
        appId: "1:98251789607:web:97ec185018ebad46"
    },
    DEV: {
        apiKey: "AIzaSyBqTSLbJxSjxLNWggKj6UNoNxBxgLlXPeo",
        authDomain: "busstop-68aac.firebaseapp.com",
        databaseURL: "https://busstop-68aac.firebaseio.com",
        projectId: "busstop-68aac",
        storageBucket: "busstop-68aac.appspot.com",
        messagingSenderId: "98251789607",
        appId: "1:98251789607:web:97ec185018ebad46"
    }
}

export default firebaseConfig

  