import React from 'react'
import './App.css'
import initApp from './initializeApp'

import { BrowserRouter as Router, Switch, Route, Redirect } from 'react-router-dom';
import theme from '../config/themes'
import { ThemeProvider } from '@material-ui/styles';
//import PrivateRoutes from '../config/Routes/PrivateRoutes'

//import { watchUserAuth } from '../firebase/auth'
import { routes } from '../config' 
import loadable from '@loadable/component'
import { SnackbarProvider } from 'notistack';

const LoadablePublicApp = loadable(() => import('./Public/PublicApp'))
const LoadablePrivateRoutes = loadable(() => import('../config/Routes/PrivateRoutes'))
const LoadableAdminRoutes = loadable(() => import('../config/Routes/AdminRoutes'))


function App ({isAuth, userRole, emailVerified, dialog }) {
  /* const test = []
   let i = 0;
   const max =10
  while (i<max) {
    test.push(i);
    i++
  } */


  const isAppUser = emailVerified && isAuth
  const isAdmin = userRole && userRole.admin
  const isPublicUser = !isAuth || !emailVerified
  const role = isAdmin ? 'admin' : 'appUser'
  

  return (

    <div id="App">
       <ThemeProvider theme={theme}>
          <SnackbarProvider maxSnack={3}>
            <Router >
              <Switch>
                  {isAppUser && <Route path="/app" render={(props)=><LoadablePrivateRoutes {...props} role={role}/>} />} 
                  {isAdmin && <Route path="/admin" render={()=><LoadableAdminRoutes />} /> }  
                  {isPublicUser && <Route path="/" render={()=><LoadablePublicApp />} />}
              </Switch>
              {!isAuth && <Redirect to={routes.public.LOGIN} /> /*cahnged to registration shoud be landingPage */}
              {(isAuth && emailVerified ) && <Redirect to={routes.private.PUBLIC_FEED} />}
              {isAdmin && <Redirect to={routes.private.PUBLIC_FEED} />}
              {(isAuth && !emailVerified ) && <Redirect to={routes.public.EMAIL_VERIFICATION_MESSAGE} />}
          </Router>
        </SnackbarProvider>
      </ThemeProvider>
    </div>
       
       
  )
}


export default initApp()(App)
