import { dispatch } from 'store/'

export const dispatchPosts = (posts) => {
    return dispatch({type: 'FETCH_POSTS', payload: posts} )
}

export const dispatchFilteredPosts = (filteredPosts) => {
    return dispatch({type: 'FILTERED_POSTS', payload: filteredPosts})
}

export const dispatchMyPosts = (posts, userId)  => {
    const myPosts = posts.filter(post=> {
        return post.data.userId === userId
    })

    return dispatch({type: 'FILTER_MYPOSTS', payload: myPosts})
}