import _ from 'lodash'

export const filterEvents = (data, filter) =>{
    const filteredList = _.values(data)
    .filter(dataItem => {
    // remove events that do not match the current filter
        return dataItem.data.indexOf(filter) >=0
    })

    
    return filteredList
}


export const listFilter = (initList, filter, filterProps, propKeys) => {
    //console.log(initList, filter, filterProps, '-----------------------------')
    const filteredArr = []
    // eslint-disable-next-line
    initList.filter(listItem => {
        if(listItem==='undefined' || typeof(listItem)==='undefined') return null
     
        // eslint-disable-next-line
        filterProps.map(filterProp=>{
            if(typeof(listItem['profile'][filterProp])==='undefined') return null
            if(listItem['profile'][filterProp].toLowerCase().search(filter.toLowerCase())!==-1)
                return filteredArr.push(listItem)
                
        })
    })    
    return filteredArr
}