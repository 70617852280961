import React, { Component } from 'react'
import { connect } from 'react-redux'
import { watchPosts } from 'firebase/database/postHandler'
import FeedList from 'Components/Lists/MediaLists/FeedList'
import FeedItemDetails from './FeedItemDetails'
import Fade from '@material-ui/core/Fade'
import Filter from './Filter'
import { watchCategories, watchUserCategories } from 'firebase/database/categorieHandler'
import Loading from 'Components/Loading/Spinner'

class PublicFeed extends Component {
    constructor (props) {
        super(props)
        this.state = {
            activeItemIndex: 0,
            isHidden: {
                list: false,
                details: true,
            },
            filteredPosts : null,
        }
    }
    componentDidMount () {
        watchPosts(true)
        watchCategories(true)
        watchUserCategories(true, this.props.userId)
    }
    componentWillUnmount () {
        watchPosts(false)
    }


    onItemClick = (index) => {
      
        const isHidden = {
            details: !this.state.isHidden.details,
            list: !this.state.isHidden.list
        }
        this.setState({activeItemIndex: index, isHidden: isHidden})
    }

    


 
    render() {
     
        const { userCategories } = this.props
        if(this.props.posts===null)return <Loading title='Posts werden geladen'/>
        if(this.props.posts.length===0)return <div style={{textAlign: 'center'}}>Keine Posts vorahnden</div>
        return (
            <React.Fragment >
                {this.state.isHidden.details && 
                    <Filter data={this.props.categories} userId={this.props.userId} userCategories={userCategories || []}/>
                }
                <Fade in={!this.state.isHidden.list} timeout={{enter: 480, exit: 360}} unmountOnExit>
                    <FeedList
                        userId={this.props.userId}
                        items={this.props.filteredPosts || this.props.posts}
                        onItemClick={this.onItemClick}
                        activeItemIndex={this.state.activeItemIndex}
                        isAdmin={this.props.isAdmin}

                    />
                </Fade>
                 <Fade in={!this.state.isHidden.details} timeout={{enter: 480, exit: 360}} unmountOnExit>
                    <div>
                        <FeedItemDetails 
                            item={this.props.filteredPosts===null || this.props.filteredPosts.length===0 
                                ? this.props.posts[this.state.activeItemIndex].data
                                : this.props.filteredPosts[this.state.activeItemIndex].data} 
                            onCloseClick={this.onItemClick} 
                            userId={this.props.userId}
                            index={this.state.activeItemIndex}
                            isAdmin={this.props.isAdmin}
                    
                            />
                    </div>
                </Fade>
              
                 </React.Fragment>   
              
        )
    }
}

const mapStateToProps = state => ({
    posts: state.posts.data,
    isAdmin: state.user.role.admin,
    userId: state.user.id,
    categories: state.categories.data,
    userCategories: state.categories.userCategories,
    filteredPosts: state.posts.filtered
})


export default connect(mapStateToProps)(PublicFeed)