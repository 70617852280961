import React from 'react'


const privateStyle = {}
const publicStyle = {
    root: {
        backgroundColor: 'gray',
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
    },
    wrapper: {
        textAlign: 'justify',
        marginTop: '60px',
        width: '80%',
        padding: '8px',
        margin: '0 auto 0 auto',
        backgroundColor: 'white',
        borderRadius: '24px'
    }
}
const Datenschutz = ({publicView}) => {
   
    const styles = publicView ? publicStyle : privateStyle
    const text = 'Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum'
    const textArr = []
    for (let i = 0; i < 5; i++) {
        textArr.push(<p>{text}</p>)
    }
    
    return (
        <div style={styles.root}>
            <div style={styles.wrapper}>
                <Content datenschutzEmail='datenschutz@brückenbauer.org' />
            </div>
        </div>
    )
}

export default Datenschutz



const Content = (props)=>(

    <div class="container-fluid">

	<div class="container">
		<div class="maincontent content-holder">
			<div class="col-sm-12 content-holder-spacer"></div>
			<h1>Datenschutzerkl&auml;rung</h1>
<h1>A. Name, Anschrift und Kontaktdaten des Verantwortlichen</h1>
<p>Der Verantwortliche im Sinne der Datenschutz-Grundverordnung und anderer nationaler Datenschutzgesetze der Mitgliedsstaaten sowie sonstiger datenschutzrechtlicher Bestimmungen f&uuml;r die Verarbeitung personenbezogener Daten im Rahmen des Portals Brückenbauer (nachfolgend &bdquo;das Portal&ldquo; genannt) ist der:</p>
<p><em>Generationenbund BGL e.V.</em><br />
<em>Hauptstraße 22</em><br />
<em>83454 Anger</em><br />
<em>Deutschland</em><br />
<em>Telefon: +49 8654 989 450 0</em><br />
<em>E-Mail: info@generationenbund-bgl.de</em>
<em>&nbsp;</em></p>
<h1>B. Kontaktdaten des Datenschutzbeauftragten</h1>
<p>Der Datenschutzbeauftragter <em>des Generationenbund BGL e.V.</em> ist wie folgt erreichbar:</p>
<p><em>Walter Reinhold</em><br />
<em>Telefon: +49 8654 989 450 0</em><br />
<em>E-Mail: {props.datenschutzEmail} </em></p>
<h1>C. Verarbeitung personenbezogener Daten</h1>
<p>Im Rahmen der nachfolgenden Darstellung werden unter anderem folgende Begrifflichkeiten verwendet:</p>
<ul>
<li><strong>Besucher:</strong> Personen (m/w), die das Portal ohne Registrierung aufrufen (keine Nutzung der Funktionalit&auml;ten und Dienste des Portals m&ouml;glich).</li>
<li><strong>Nutzer:</strong> Personen (m/w), die sich zur Nutzung des Portals registriert haben und das Portal im Rahmen eines Nutzungsverh&auml;ltnisses auf Grundlage der Nutzungsbestimmungen des Verantwortlichen nutzen.</li>
</ul>
<h2>I. Besuch Portal und Kontakt</h2>
<h3>1. Besuch des Portals</h3>
<h4>1.1&nbsp; Beschreibung der Datenverarbeitung</h4>
<p>Beim Aufrufen des Portals werden durch den auf Ihrem Endger&auml;t zum Einsatz kommenden Browser automatisch Informationen an den Server des Portals gesendet. Folgende Informationen werden dabei erhoben:</p>
<ul>
<li>Die IP-Adresse des Besuchers</li>
<li>Datum und Uhrzeit des Zugriffs</li>
<li>Informationen &uuml;ber den Browsertyp und die verwendete Version</li>
<li>Das Betriebssystem des Besuchers</li>
<li>Der Internet-Service-Provider des Besuchers</li>
<li>Websites, von denen das System des Besuchers auf unsere Internetseite gelangt</li>
</ul>
<p>Diese Daten werden zudem &ndash; anonymisiert &ndash; in sogenannten Logfiles unseres Systems gespeichert. Eine Speicherung dieser Daten zusammen mit anderen personenbezogenen Daten des Besuchers findet nicht statt.</p>
<p>Ebenso wenig findet eine Weitergabe dieser Daten an Dritte statt. Unter strikter Beachtung der einschl&auml;gigen datenschutzrechtlichen Bestimmungen k&ouml;nnen im Rahmen des Hostings und der Wartung unserer Systeme allenfalls unsere hierzu eingebundenen und sorgf&auml;ltig ausgew&auml;hlten Dienstleister Empf&auml;nger der Daten sein. Dies erfolgt auf Basis von Auftragsverarbeitungsvereinbarungen durch die die Dienstleister vertraglich gebunden sind und wir insoweit f&uuml;r die Verarbeitung der Daten verantwortlich bleiben.</p>
<h4>1.2&nbsp; Zweck der Datenverarbeitung</h4>
<p>Die vor&uuml;bergehende Speicherung der IP-Adresse durch das System ist notwendig, um eine Auslieferung der Website an den Rechner des Besuchers zu erm&ouml;glichen. Hierf&uuml;r muss die IP-Adresse des Besuchers f&uuml;r die Dauer der Sitzung gespeichert werden.</p>
<p>Die anonymisierte Speicherung der Daten in Logfiles erfolgt, um die Funktionsf&auml;higkeit der Website sicherzustellen. Zudem dienen die Dateien der Optimierung der Website und zur Sicherstellung der Sicherheit unserer informationstechnischen Systeme.</p>
<h4>1.3&nbsp; Rechtsgrundlage der Verarbeitung</h4>
<p>Rechtsgrundlage f&uuml;r die vor&uuml;bergehende Speicherung der Daten ist Art. 6 Abs. 1 lit. f) DSGVO. Das hierf&uuml;r erforderliche berechtigte Interesse an der Datenverarbeitung besteht in den vorstehend genannten Verarbeitungszwecken.</p>
<h4>1.4&nbsp; Dauer der Verarbeitung / L&ouml;schung der Daten</h4>
<p>Die Daten werden gel&ouml;scht, sobald sie f&uuml;r die Erreichung des Zweckes ihrer Erhebung bzw. Verarbeitung nicht mehr erforderlich sind. Im Falle der Erhebung und Speicherung der Daten zur Bereitstellung der Website ist dies der Fall, wenn die jeweilige Sitzung beendet ist</p>
<h4>1.5&nbsp; Widerspruchsrecht</h4>
<p>Sie haben grunds&auml;tzlich gem&auml;&szlig; Art. 21 DSGVO das Recht, aus Gr&uuml;nden, die sich aus Ihrer besonderen Situation ergeben, jederzeit gegen die Verarbeitung der Daten gem&auml;&szlig; Ziff. 1.1 Widerspruch einzulegen. Wir verarbeiten diese Daten dann nicht mehr, es sei denn, dass zwingende schutzw&uuml;rdige Gr&uuml;nde f&uuml;r die Verarbeitung nachgewiesen werden k&ouml;nnen, die Ihre Interessen, Rechte und Freiheiten &uuml;berwiegen, oder sofern die Verarbeitung der Geltendmachung, Aus&uuml;bung oder Verteidigung von Rechtsanspr&uuml;chen dient.</p>
<p>Zur Aus&uuml;bung eines entsprechenden Widerspruchs kann sich der Besucher insbesondere per Email unter Verwendung der Email-Adresse <strong><em>{props.datenschutzEmail} </em></strong>an uns wenden. Im Falle eines begr&uuml;ndeten Widerspruchs, werden die Daten gel&ouml;scht.</p>

<h3>2. Anfragen über E-Mail-Kontakt</h3>
<h5>2.1. Beschreibung und Umfang der Datenverarbeitung</h5>
<p>Im Rahmen des Portals haben Besucher zun&auml;chst die M&ouml;glichkeit, Anfragen an uns &uuml;ber eine E-Mail Funktion zu stellen. Der Besucher hat dabei keine personenbezogenen Daten anzugeben. Lediglich bei registrierten Nutzern wird der Benutzername des Nutzers gespeichert.</p>
<p>Im Zeitpunkt der Absendung der Nachricht werden zudem folgende Daten gespeichert:</p>
<ul>
<li>E-Mail Adresse des Absenders</li>
<li>Datum und Uhrzeit</li>
<li>Nachricht selbst</li>
</ul>
<p>Kontaktaufnahme &uuml;ber die bereitgestellte E-Mail-Adresse In diesem Fall werden die mit der E-Mail &uuml;bermittelten personenbezogenen Daten des Besuchers gespeichert.</p>
<p>Die Daten werden ausschlie&szlig;lich f&uuml;r die Bearbeitung der jeweiligen Anfrage verwendet. Es erfolgt in diesem Zusammenhang keine Weitergabe der Daten an Dritte. Unter strikter Beachtung der einschl&auml;gigen datenschutzrechtlichen Bestimmungen k&ouml;nnen im Rahmen des Hostings und der Wartung unserer Systeme allenfalls unsere hierzu eingebundenen und sorgf&auml;ltig ausgew&auml;hlten Dienstleister Empf&auml;nger der Daten sein. Dies erfolgt auf Basis von Auftragsverarbeitungsvereinbarungen durch die die Dienstleister vertraglich gebunden sind und wir insoweit f&uuml;r die Verarbeitung der Daten verantwortlich bleiben.</p>
<h5>2.2. Zweck der Datenverarbeitung</h5>
<p>Die Verarbeitung der personenbezogenen Daten aus der Registrierung bzw. Ihrer Emailanfrage erfolgt allein zur Bearbeitung der Kontaktaufnahme. Im Falle einer Kontaktaufnahme stellt dies auch das erforderliche berechtigte Interesse an der Verarbeitung der Daten dar. Die sonstigen w&auml;hrend des Absendevorgangs verarbeiteten personenbezogenen Daten dienen dazu, einen Missbrauch des Kontaktformulars zu verhindern und die Sicherheit unserer informationstechnischen Systeme sicherzustellen.</p>
<h5>2.3. Rechtsgrundlage f&uuml;r die Datenverarbeitung</h5>
<p>Rechtsgrundlage f&uuml;r die Verarbeitung der Daten, die im Zusammenhang mit einer Anfrage (per E-Mail oder &uuml;ber den Chat bzw. das Kontaktformular) &uuml;bermittelt werden, ist Art. 6 Abs. 1 lit. f) DSGVO. Zielt der E-Mail-Kontakt auf den Abschluss eines Vertrages ab, so ist zus&auml;tzliche Rechtsgrundlage f&uuml;r die Verarbeitung Art. 6 Abs. 1 lit. b) DSGVO.</p>
<h5>2.4. Dauer der Verarbeitung</h5>
<p>Die Daten werden gel&ouml;scht, sobald sie f&uuml;r die Erreichung des Zweckes ihrer Erhebung nicht mehr erforderlich sind. F&uuml;r die personenbezogenen Daten aus dem Chat oder der Eingabemaske des Kontaktformulars und diejenigen, die per E-Mail &uuml;bersandt wurden, ist dies dann der Fall, wenn die jeweilige Konversation bzw. Anfrage mit dem Besucher beendet ist. Beendet ist die Konversation dann, wenn sich aus den Umst&auml;nden entnehmen l&auml;sst, dass der betroffene Sachverhalt abschlie&szlig;end gekl&auml;rt ist. Die Daten aus dem Chat und dem Kontaktformular werden sp&auml;testens einen Monat nach Anfrage gel&ouml;scht.</p>
<h5>2.5. Widerspruchsrecht</h5>
<p>Nimmt der Besucher Kontakt per Email oder &uuml;ber den Chat bzw. das Kontaktformular mit uns auf, so kann er der Speicherung seiner personenbezogenen Daten jederzeit gem&auml;&szlig; Art. 21 DSGVO widersprechen. In einem solchen Fall kann die Konversation nicht fortgef&uuml;hrt werden.</p>
<p>Zur Aus&uuml;bung eines entsprechenden Widerspruchs oder Widerrufs kann sich der Besucher insbesondere per Email unter Verwendung der Email-Adresse <strong><em>{props.datenschutzEmail} </em></strong>&nbsp;&nbsp;an uns wenden. Die Daten, die im Zuge der Kontaktaufnahme gespeichert wurden, werden in den vorgenannten F&auml;llen gel&ouml;scht.</p>
<h2>II. Registrierung, Profile und entgeltliche Zusatzleistungen</h2>
<h3>1. Registrierung bei Brückenbauer</h3>
<h4>1.1. Beschreibung und Umfang der Verarbeitung</h4>
<p>Um die im Rahmen des Portals angebotenen Leistungen und Funktionalit&auml;ten nutzen zu k&ouml;nnen, ist eine Registrierung als Nutzer erforderlich. Hierzu ben&ouml;tigen wir zun&auml;chst folgende Daten:</p>
<ul>
<li>E-Mail-Adresse</li>
<li>Ort</li>
<li>Benutzername / Pseudonym</li>
<li>Passwort (verschl&uuml;sselt)</li>
<li>Best&auml;tigung der Akzeptanz von Nutzungsbedingungen und Datenschutzerkl&auml;rung</li>   
</ul>
<p>Im Zeitpunkt der Absendung der Registrierungsanfrage werden zudem folgende Daten (Logfile-Daten) gespeichert:</p>
<ul>
<li>Die IP-Adresse des Besuchers</li>
<li>Datum und Uhrzeit des Zugriffs</li>
<li>Informationen &uuml;ber den Browsertyp und die verwendete Version</li>
<li>Das Betriebssystem des Besuchers</li>
</ul>   
<p>Die vorgenannten Daten sind f&uuml;r die Verwendung der App erforderlich. Diese Daten werden von uns gespeichert und f&uuml;r den Abschluss und die Durchf&uuml;hrung des Nutzungsvertrages &uuml;ber die Inanspruchnahme der Leistungen bzw. Funktionalit&auml;ten des Portals verarbeitet.</p>
<p>Ort und Username / Pseudonym sind nach Registrierung nur f&uuml;r registrierte Nutzer &uuml;ber das Portal einsehbar, die &uuml;brigen Daten nicht.</p>
<p>Unter strikter Beachtung der einschl&auml;gigen datenschutzrechtlichen Bestimmungen k&ouml;nnen im Rahmen des Hostings und der Wartung unserer Systeme allenfalls unsere hierzu eingebundenen und sorgf&auml;ltig ausgew&auml;hlten Dienstleister Empf&auml;nger der Daten sein. Dies erfolgt auf Basis von Auftragsverarbeitungsvereinbarungen durch die die Dienstleister vertraglich gebunden sind und wir insoweit f&uuml;r die Verarbeitung der Daten verantwortlich bleiben.</p>
<h4>1.2. Zweck der Verarbeitung</h4>
<p>Die Verarbeitung der im Rahmen der Registrierung erhobenen Daten dient des Abschlusses und der Durchf&uuml;hrung des Nutzungsvertrages mit Nutzern &uuml;ber die Inanspruchnahme des Portals bzw. dessen Leistungen und Funktionalit&auml;ten. Die Logfile-Daten dienen dar&uuml;ber hinaus auch dazu, einen Missbrauch der Registrierung zu verhindern und die Sicherheit unserer informationstechnischen Systeme sicherzustellen.</p>
<h4>1.3. Rechtsgrundlage der Verarbeitung</h4>
<p>Rechtsgrundlage f&uuml;r die Verarbeitung der Zugangsdaten, die im Zuge der Registrierung erhoben werden, ist Art. 6 Abs. 1 lit. b) DSGVO.</p>
<h4>1.4. Dauer der Verarbeitung</h4>
<p>Nach vollst&auml;ndiger Abwicklung des Nutzungsvertrages &uuml;ber die Inanspruchnahme der Leistungen des Portals bzw. L&ouml;schung des Profils des Nutzers werden die Daten gel&ouml;scht bzw. die Verarbeitung insoweit eingeschr&auml;nkt als dies f&uuml;r steuer- und handelsrechtliche Aufbewahrungspflichten erforderlich ist. Sp&auml;testens nach Ablauf der entsprechenden gesetzlichen Aufbewahrungsfristen werden die Daten gel&ouml;scht, sofern der Nutzer nicht ausdr&uuml;cklich in eine weitere Nutzung der Daten eingewilligt hat.</p>
<h3>2. Freiwillige Profilangaben des Nutzers</h3>
<h5>2.1. Beschreibung und Umfang der Verarbeitung</h5>
<p>F&uuml;r die &ouml;ffentlich einsehbare Darstellung seiner Person im Rahmen des Portals legt der Nutzer ein Nutzer-Profil an. Zur Erstellung dieses Profils kann der Nutzer die aus der entsprechenden Eingabemaske ersichtlichen Daten angeben (z.B. Vorname, Nachname, Wohnort, etc.).</p>
<p>Die vorgenannten Daten werden zum Zwecke der Vertragsdurchf&uuml;hrung bzw. zur Ausf&uuml;hrung der im Rahmen des Portals angebotenen Funktionalit&auml;ten gespeichert und verarbeitet, insbesondere zur eigenen Darstellung seiner Person durch den Nutzer. Soweit der Nutzer freiwillig diese Daten hinterl&auml;sst, sind diese &ouml;ffentlich &uuml;ber das Portal einsehbar.</p>
<p>Unter strikter Beachtung der einschl&auml;gigen datenschutzrechtlichen Bestimmungen k&ouml;nnen im Rahmen des Hostings und der Wartung unserer Systeme allenfalls unsere hierzu eingebundenen und sorgf&auml;ltig ausgew&auml;hlten Dienstleister Empf&auml;nger der Daten sein. Dies erfolgt auf Basis von Auftragsverarbeitungsvereinbarungen durch die die Dienstleister vertraglich gebunden sind und wir insoweit f&uuml;r die Verarbeitung der Daten verantwortlich bleiben.</p>
<h5>2.2. Zweck der Verarbeitung</h5>
<p>Die Verarbeitung der im Rahmen der Registrierung erhobenen Daten dient der Durchf&uuml;hrung des Nutzungsvertrages mit dem Nutzer &uuml;ber die Inanspruchnahme des Portals bzw. dessen Leistungen und Funktionalit&auml;ten. Die Logfile-Daten dienen dar&uuml;ber hinaus dazu, einen Missbrauch der Registrierung &nbsp;zu verhindern und die Sicherheit unserer informationstechnischen Systeme sicherzustellen.</p>
<h5>2.3. Rechtsgrundlage der Verarbeitung</h5>
<p>Rechtsgrundlage f&uuml;r die Verarbeitung der Daten ist Art. 6 Abs. 1 lit. b) DSGVO.</p>
<h5>2.4. Dauer der Verarbeitung</h5>
<p>Nach vollst&auml;ndiger Abwicklung des Nutzungsvertrages &uuml;ber die Inanspruchnahme der Leistungen des Portals bzw. L&ouml;schung Ihres Nutzer-Profils werden Ihre Daten gel&ouml;scht bzw. die Verarbeitung insoweit eingeschr&auml;nkt als dies f&uuml;r steuer- und handelsrechtliche Aufbewahrungspflichten erforderlich ist. Sp&auml;testens nach Ablauf der entsprechenden gesetzlichen Aufbewahrungsfristen werden Ihre Daten gel&ouml;scht, sofern Sie nicht ausdr&uuml;cklich in eine weitere Nutzung Ihrer Daten eingewilligt haben.</p>

{/*
<h2>III. Benachrichtigungen und private Nachrichten</h2>
<h3>1. Benachrichtigungen&nbsp;</h3>
<h4>1.1. Beschreibung und Umfang der Verarbeitung</h4>
<p>Die Rahmen des Zwecks der Plattform &ndash; die Kontaktaufnahme und den Austausch zwischen Nutzern zu erm&ouml;glichen &ndash; und der damit verbundenen Vertragsdurchf&uuml;hrung wird die E-Mail-Adresse des Nutzers auch dazu verarbeitet, dem Nutzer Mitteilungen bzw. Benachrichtigungen im Zusammenhang mit den &uuml;ber das Portal bereitgestellten Funktionalit&auml;ten zukommen zu lassen (z.B. Benachrichtigung &uuml;ber Kontaktaufnahmewunsch eines anderen Nutzers, etc.).</p>
<p>Unter strikter Beachtung der einschl&auml;gigen datenschutzrechtlichen Bestimmungen k&ouml;nnen mit der Auslieferung der Benachrichtigungen auch unsere hierzu eingebundenen und sorgf&auml;ltig ausgew&auml;hlten Dienstleister Empf&auml;nger der Daten sein. Dies erfolgt auf Basis von Auftragsverarbeitungsvereinbarungen durch die die Dienstleister vertraglich gebunden sind und wir insoweit f&uuml;r die Verarbeitung der Daten verantwortlich bleiben.</p>
<h4>1.2. Zweck der Verarbeitung</h4>
<p>Die Verarbeitung der Daten erfolgt zum Zwecke des Versandes von Benachrichtigungen im Rahmen der vertraglich bereitgestellten Funktionalit&auml;ten der Plattform.</p>
<h4>1.3. Rechtsgrundlage der Verarbeitung</h4>
<p>Rechtsgrundlage f&uuml;r die Verarbeitung der Daten ist Art. 6 Abs. 1 lit. b) DSGVO.</p>
<h4>1.4. Dauer der Verarbeitung</h4>
<p>Nach vollst&auml;ndiger Abwicklung des Vertrages werden Ihre Daten gel&ouml;scht, sofern Sie nicht ausdr&uuml;cklich in eine weitere Nutzung Ihrer Daten eingewilligt haben oder wir uns eine dar&uuml;ber hinausgehende Datenverwendung vorbehalten, die gesetzlich erlaubt ist und &uuml;ber die wir Sie in dieser Erkl&auml;rung informieren.</p>
<h3>2. Private Nachrichten</h3>
<h5>2.1. Beschreibung und Umfang der Verarbeitung</h5>
<p>Als eine wesentliche Funktionalit&auml;t des Portals haben Nutzer (gem&auml;&szlig; den Bestimmungen der Nutzungsbedingungen) die M&ouml;glichkeit, sich &uuml;ber ein portal-internes Nachrichtensystem private Nachrichten zuzusenden und auf diese Weise in Kontakt zu treten.</p>
<p>Der Versand erfolgt &uuml;ber ein entsprechendes Absendeformular im Rahmen des Portals. Der Nutzer muss in diesem Fall nur noch seine Nachricht eingeben. Als Absender- und Empf&auml;ngerkennung dienen dabei die Benutzernamen der Nutzer. Au&szlig;er den Benutzernamen und dem Nachrichteninhalt sind keine weiteren Daten der beteiligten Nutzer Gegenstand der &uuml;bermittelten privaten Nachricht.</p>
<h5>2.2. Zweck der Verarbeitung</h5>
<p>Zweck der Verarbeitung ist der Versand von privaten Nachrichten &uuml;ber das Portal, die eine wesentliche Funktionalit&auml;t des Portals im Rahmen des Nutzungsverh&auml;ltnisses mit dem Nutzer darstellt.</p>
<h5>2.3. Rechtsgrundlage der Verarbeitung</h5>
<p>Rechtsgrundlage f&uuml;r die Verarbeitung der Daten ist Art. 6 Abs. 1 lit. b) DSGVO.</p>
<h5>2.4. Dauer der Verarbeitung</h5>
<p>Nach vollst&auml;ndiger Abwicklung des Vertrages (der Mitgliedschaft) werden Ihre Daten gel&ouml;scht, sofern Sie nicht ausdr&uuml;cklich in eine weitere Nutzung Ihrer Daten eingewilligt haben oder wir uns eine dar&uuml;ber hinausgehende Datenverwendung vorbehalten, die gesetzlich erlaubt ist und &uuml;ber die wir Sie in dieser Erkl&auml;rung informieren.</p>
<p>Bei den Empf&auml;ngern der Benachrichtigungen werden die Daten erst dann gel&ouml;scht, wenn das entsprechende Vertragsverh&auml;ltnis mit diesen vollst&auml;ndig abgewickelt wurde.</p>
*/}
<h2>IV. Cookies, Webanalyse und Social Media</h2>
<h3>1. Cookies</h3>
<h4>1.1. Beschreibung und Umfang der Verarbeitung</h4>
<p>Im Rahmen des Portals werden Cookies eingesetzt. Dabei handelt es sich um kleine Textdateien, die auf Ihrem Endger&auml;t dem von Ihnen verwendeten Browser zugeordnet gespeichert werden und durch die der Stelle, die den Cookie setzt, bestimmte Informationen zuflie&szlig;en.</p>
<p>Wir setzten solche Cookies ein, um den Besuch unseres Portals attraktiv zu gestalten, die Nutzung bestimmter Funktionen zu erm&ouml;glichen sowie um Ihnen passende Produkte anzeigen zu k&ouml;nnen.</p>
<p>Einige der Cookies, die im Rahmen des Portals eingesetzt werden, werden nach Ende der jeweiligen Sitzung, also nach Schlie&szlig;en Ihres Browsers, wieder gel&ouml;scht (so genannte Session-Cookies). Andere Cookies dagegen verbleiben auf Ihrem Endger&auml;t auch nach Beendigung der Sitzung und erm&ouml;glichen uns, Ihren Browser beim n&auml;chsten Besuch unseres Portals wieder zu erkennen (Persistente Cookies).</p>
<p>Die Dauer der Speicherung der jeweiligen Cookies k&ouml;nnen Sie der &Uuml;bersicht in den Cookie-Einstellungen Ihres Browsers entnehmen. Sie k&ouml;nnen Ihren Browser zudem so einstellen, dass Sie &uuml;ber das Setzen von Cookies informiert werden und einzeln &uuml;ber deren Annahme entscheiden oder die Annahme von Cookies f&uuml;r bestimmte F&auml;lle oder generell ausschlie&szlig;en. Insoweit verweisen wir auf die jeweiligen Cookie-Einstellungen in den jeweiligen Browsern.</p>
<p>Wir weisen jedoch darauf hin, dass bei Verhinderung des Setzens von Cookies die Funktionalit&auml;t unseres Portals eingeschr&auml;nkt sein kann.</p>
<h4>1.2. Zweck der Verarbeitung der Daten</h4>
<p>Zweck der Verarbeitung ist zum einen die Nutzung von Websites f&uuml;r die Nutzer zu vereinfachen. Zudem k&ouml;nnen einige Funktion unseres Portals ohne den Einsatz von Cookies nicht angeboten werden. F&uuml;r diese ist es zudem erforderlich, dass der Browser auch nach einem Seitenwechsel wiedererkannt wird.</p>
<p>In den vorgenannten Zwecken liegt auch unser berechtigtes Interesse in der Verarbeitung der Daten nach Art. 6 Abs. 1 lit. f) DSGVO.</p>
<h4>1.3. Rechtsgrundlage der Verarbeitung</h4>
<p>Rechtsgrundlage f&uuml;r die Verarbeitung der Daten unter Verwendung von Cookies ist Art. 6 Abs. 1 lit. f) DSGVO.</p>
<h4>1.4. Dauer der Verarbeitung</h4>
<p>Die Cookies werden auf Ihrem Endger&auml;t gespeichert und von diesem an das Portal &uuml;bermittelt. Daher der jeweilige Nutzer die volle Kontrolle &uuml;ber die Verwendung der Cookies. Durch eine &Auml;nderung der Cookie-Einstellungen in ihrem Internetbrowser k&ouml;nnen sie die &Uuml;bertragung von Cookies deaktivieren oder einschr&auml;nken. Bereits in Ihrem Endger&auml;t gespeicherte Cookies k&ouml;nnen jederzeit gel&ouml;scht werden. Dies kann auch automatisiert erfolgen. Werden Cookies f&uuml;r das Portal jedoch deaktiviert, k&ouml;nnen m&ouml;glicherweise nicht mehr alle Funktionen des Portals vollumf&auml;nglich genutzt werden.</p>
<h3>2. Google Analytics</h3>
<h5>2.1. Beschreibung und Umfang der Verarbeitung</h5>
<p>Im Rahmen des Portals wird zudem zur Webanalyse Google Analytics eingesetzt. Dabei handelt es sich um einen Analysedienst der Google LLC (www.google.de). Google Analytik verwendeten Methoden, die eine Analyse der Benutzung unseres Portals durch den jeweiligen Nutzer erm&ouml;glichen. Auf diese Weise ist es uns m&ouml;glich, unser Angebot zur optimieren und an die Bed&uuml;rfnisse der Nutzer anzupassen.</p>
<p>Die automatisch erhobenen Informationen &uuml;ber die Nutzung des Portals durch die Nutzer werden dabei in der Regel an einen Server von Google in den USA &uuml;bertragen und dort gespeichert. Durch die im Rahmen unseres Portals eingesetzte Aktivierung der IP-Anonymisierung wird die IP-Adresse vor der &Uuml;bermittlung innerhalb der Mitgliedstaaten der Europ&auml;ischen Union oder in anderen Vertragsstaaten des Abkommens &uuml;ber den europ&auml;ischen Wirtschaftsraum relevant gek&uuml;rzt. Nur in Ausnahmef&auml;llen wird die volle IP-Adresse an einen Server von Google in den USA &uuml;bertragen und dort gek&uuml;rzt. Die im Rahmen von Google Analytics&nbsp; von Ihrem Browser &uuml;bermittelte anonymisierte IP-Adresse wird grunds&auml;tzlich nicht mit anderen Daten von Google zusammengef&uuml;hrt.</p>
<p>Google LLC hat seinen Hauptsitz in den USA und ist zertifiziert unter dem EU-US-Privacy Shield. Aufgrund dieses Abkommens zwischen den USA und der Europ&auml;ischen Kommission hat letztere f&uuml;r unter dem Privacy Shield zertifizierte Unternehmen ein angemessenes Datenschutzniveau festgestellt.</p>
<h5>2.2. Zweck der Verarbeitung</h5>
<p>Zweck der Verarbeitung ist die Analyse von Nutzerverhalten zur Optimierung des Angebots und zur Anpassung unseres Portals an die Bed&uuml;rfnisse der Nutzer. Darin liegt zugleich das berechtigte Interesse im Rahmen von Art. 6 Abs. 1 lit. f) DSGVO.</p>
<h5>2.3. Rechtsgrundlage der Verarbeitung</h5>
<p>Rechtsgrundlage der Verarbeitung der Daten&nbsp; im Rahmen der Webanalyse ist Art. 6 Abs. 1 lit. f) DSGVO.</p>
<h5>2.4. Dauer der Verarbeitung</h5>
<p>Nach Zweck Fortfall und Ende des Einsatzes von Google Analytics durch uns werden die in diesem Zusammenhang erhobenen Daten gel&ouml;scht.</p>
<p>Sie k&ouml;nnen die Erfassung der durch das Cookie erzeugten und auf Ihre Nutzung der Website bezogenen Daten (inkl. Ihrer IP-Adresse) an Google sowie die Verarbeitung dieser Daten durch Google verhindern, indem sie das unter dem folgenden Link verf&uuml;gbare Browser-Plug-In herunterladen und installieren: http://tools.google.com/dlpage/gaoptout?hl=de</p>
<p>Alternativ verhindern Sie mit einem Klick auf diesen Link, dass Google Analytics innerhalb dieser Website Daten &uuml;ber Sie erfasst. Mit dem Klick auf obigen Link laden Sie ein &bdquo;Opt-Out-Cookie&ldquo; herunter. Ihr Browser muss die Speicherung von Cookies also hierzu grunds&auml;tzlich erlauben. L&ouml;schen Sie Ihre Cookies regelm&auml;&szlig;ig, ist ein erneuter Klick auf den Link bei jedem Besuch dieser Website vonn&ouml;ten.</p>
<h1>D. Rechte der betroffenen Person</h1>
<p>Werden personenbezogene Daten von Ihnen verarbeitet, sind Sie Betroffener i.S.d. DSGVO und es stehen Ihnen folgende Rechte gegen uns, den Verantwortlichen, zu (soweit nicht bereits vorstehend unter D im Zusammenhang mit der jeweiligen Verarbeitung Ihrer Daten dargelegt):</p>
<h3>1. Auskunftsrecht</h3>
<p>Sie k&ouml;nnen gem&auml;&szlig; Art. 15 DSGVO von dem Verantwortlichen eine Best&auml;tigung dar&uuml;ber verlangen, ob personenbezogene Daten, die Sie betreffen, von uns verarbeitet werden.</p>
<p>Liegt eine solche Verarbeitung vor, k&ouml;nnen Sie von dem Verantwortlichen &uuml;ber folgende Informationen Auskunft verlangen:</p>
<p>(1)&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; die Zwecke, zu denen die personenbezogenen Daten verarbeitet werden;</p>
<p>(2)&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; die Kategorien von personenbezogenen Daten, welche verarbeitet werden;</p>
<p>(3)&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; die Empf&auml;nger bzw. die Kategorien von Empf&auml;ngern, gegen&uuml;ber denen die Sie betreffende personenbezogene Daten offengelegt wurden oder noch offengelegt werden;</p>
<p>(4)&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; die geplante Dauer der Speicherung der Sie betreffenden personenbezogenen Daten oder, falls konkrete Angaben hierzu nicht m&ouml;glich sind, Kriterien f&uuml;r die Festlegung der Speicherdauer;</p>
<p>(5)&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; das Bestehen eines Rechts auf Berichtigung oder L&ouml;schung der Sie betreffenden personenbezogenen Daten, eines Rechts auf Einschr&auml;nkung der Verarbeitung durch den Verantwortlichen oder eines Widerspruchsrechts gegen diese Verarbeitung;</p>
<p>(6)&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; das Bestehen eines Beschwerderechts bei einer Aufsichtsbeh&ouml;rde;</p>
<p>(7)&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; alle verf&uuml;gbaren Informationen &uuml;ber die Herkunft der Daten, wenn die personenbezogenen Daten nicht bei der betroffenen Person erhoben werden;</p>
<p>(8)&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; das Bestehen einer automatisierten Entscheidungsfindung einschlie&szlig;lich Profiling gem&auml;&szlig; Art.&nbsp;22 Abs.&nbsp;1 und 4 DSGVO und &ndash; zumindest in diesen F&auml;llen &ndash; aussagekr&auml;ftige Informationen &uuml;ber die involvierte Logik sowie die Tragweite und die angestrebten Auswirkungen einer derartigen Verarbeitung f&uuml;r die betroffene Person.</p>
<p>Ihnen steht das Recht zu, Auskunft dar&uuml;ber zu verlangen, ob die Sie betreffenden personenbezogenen Daten in ein Drittland oder an eine internationale Organisation &uuml;bermittelt werden. In diesem Zusammenhang k&ouml;nnen Sie verlangen, &uuml;ber die geeigneten Garantien gem. Art.&nbsp;46 DSGVO im Zusammenhang mit der &Uuml;bermittlung unterrichtet zu werden.</p>
<h3>2. Recht auf Berichtigung</h3>
<p>Sie haben gem&auml;&szlig; Art. 16 DSGVO ein Recht auf Berichtigung und/oder Vervollst&auml;ndigung Ihrer Daten gegen&uuml;ber dem Verantwortlichen, sofern die verarbeiteten personenbezogenen Daten, die Sie betreffen, unrichtig oder unvollst&auml;ndig sind. Der Verantwortliche hat die Berichtigung unverz&uuml;glich vorzunehmen.</p>
<h3>3. Recht auf Einschr&auml;nkung der Verarbeitung</h3>
<p>Unter den folgenden Voraussetzungen k&ouml;nnen Sie gem&auml;&szlig; Art. 18 DSGVO die Einschr&auml;nkung der Verarbeitung der Sie betreffenden personenbezogenen Daten verlangen:</p>
<p>(1)&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; wenn Sie die Richtigkeit der Sie betreffenden personenbezogenen f&uuml;r eine Dauer bestreiten, die es dem Verantwortlichen erm&ouml;glicht, die Richtigkeit der personenbezogenen Daten zu &uuml;berpr&uuml;fen;</p>
<p>(2)&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; die Verarbeitung unrechtm&auml;&szlig;ig ist und Sie die L&ouml;schung der personenbezogenen Daten ablehnen und stattdessen die Einschr&auml;nkung der Nutzung der personenbezogenen Daten verlangen;</p>
<p>(3)&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; der Verantwortliche die personenbezogenen Daten f&uuml;r die Zwecke der Verarbeitung nicht l&auml;nger ben&ouml;tigt, Sie diese jedoch zur Geltendmachung, Aus&uuml;bung oder Verteidigung von Rechtsanspr&uuml;chen ben&ouml;tigen, oder</p>
<p>(4)&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; wenn Sie Widerspruch gegen die Verarbeitung gem&auml;&szlig; Art.&nbsp;21 Abs.&nbsp;1 DSGVO eingelegt haben und noch nicht feststeht, ob die berechtigten Gr&uuml;nde des Verantwortlichen gegen&uuml;ber Ihren Gr&uuml;nden &uuml;berwiegen.</p>
<p>Wurde die Verarbeitung der Sie betreffenden personenbezogenen Daten eingeschr&auml;nkt, d&uuml;rfen diese Daten &ndash; von ihrer Speicherung abgesehen &ndash; nur mit Ihrer Einwilligung oder zur Geltendmachung, Aus&uuml;bung oder Verteidigung von Rechtsanspr&uuml;chen oder zum Schutz der Rechte einer anderen nat&uuml;rlichen oder juristischen Person oder aus Gr&uuml;nden eines wichtigen &ouml;ffentlichen Interesses der Union oder eines Mitgliedstaats verarbeitet werden.</p>
<p>Wurde die Einschr&auml;nkung der Verarbeitung nach den o.g. Voraussetzungen eingeschr&auml;nkt, werden Sie von dem Verantwortlichen unterrichtet bevor die Einschr&auml;nkung aufgehoben wird.</p>
<h3>4. Recht auf L&ouml;schung</h3>
<ul>
<li>L&ouml;schungspflicht</li>
</ul>
<p>Sie k&ouml;nnen von dem Verantwortlichen verlangen, dass die Sie betreffenden personenbezogenen Daten unverz&uuml;glich gel&ouml;scht werden, und der Verantwortliche ist verpflichtet, diese Daten unverz&uuml;glich zu l&ouml;schen, sofern einer der folgenden Gr&uuml;nde zutrifft:</p>
<p>(1)&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Die Sie betreffenden personenbezogenen Daten sind f&uuml;r die Zwecke, f&uuml;r die sie erhoben oder auf sonstige Weise verarbeitet wurden, nicht mehr notwendig.</p>
<p>(2)&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Sie widerrufen Ihre Einwilligung, auf die sich die Verarbeitung gem. Art.&nbsp;6 Abs.&nbsp;1 lit.&nbsp;a) DSGVO oder Art.&nbsp;9 Abs.&nbsp;2 lit.&nbsp;a) DSGVO st&uuml;tzte, und es fehlt an einer anderweitigen Rechtsgrundlage f&uuml;r die Verarbeitung.</p>
<p>(3)&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Sie legen gem. Art.&nbsp;21 Abs.&nbsp;1 DSGVO Widerspruch gegen die Verarbeitung ein und es liegen keine vorrangigen berechtigten Gr&uuml;nde f&uuml;r die Verarbeitung vor, oder Sie legen gem. Art.&nbsp;21 Abs.&nbsp;2 DSGVO Widerspruch gegen die Verarbeitung ein.</p>
<p>(4)&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Die Sie betreffenden personenbezogenen Daten wurden unrechtm&auml;&szlig;ig verarbeitet.</p>
<p>(5)&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Die L&ouml;schung der Sie betreffenden personenbezogenen Daten ist zur Erf&uuml;llung einer rechtlichen Verpflichtung nach dem Unionsrecht oder dem Recht der Mitgliedstaaten erforderlich, dem der Verantwortliche unterliegt.</p>
<p>(6)&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Die Sie betreffenden personenbezogenen Daten wurden in Bezug auf angebotene Dienste der Informationsgesellschaft gem&auml;&szlig; Art.&nbsp;8 Abs.&nbsp;1 DSGVO erhoben.</p>
<ul>
<li>Information an Dritte</li>
</ul>
<p>Hat der Verantwortliche die Sie betreffenden personenbezogenen Daten &ouml;ffentlich gemacht und ist er gem. Art.&nbsp;17 Abs.&nbsp;1 DSGVO zu deren L&ouml;schung verpflichtet, so trifft er unter Ber&uuml;cksichtigung der verf&uuml;gbaren Technologie und der Implementierungskosten angemessene Ma&szlig;nahmen, auch technischer Art, um f&uuml;r die Datenverarbeitung Verantwortliche, die die personenbezogenen Daten verarbeiten, dar&uuml;ber zu informieren, dass Sie als betroffene Person von ihnen die L&ouml;schung aller Links zu diesen personenbezogenen Daten oder von Kopien oder Replikationen dieser personenbezogenen Daten verlangt haben.</p>
<ul>
<li>Ausnahmen</li>
</ul>
<p>Das Recht auf L&ouml;schung besteht insbesondere nicht, soweit die Verarbeitung erforderlich ist</p>
<p>(1)&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; zur Aus&uuml;bung des Rechts auf freie Meinungs&auml;u&szlig;erung und Information;</p>
<p>(2)&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; zur Erf&uuml;llung einer rechtlichen Verpflichtung, die die Verarbeitung nach dem Recht der Union oder der Mitgliedstaaten, dem der Verantwortliche unterliegt, erfordert, oder zur Wahrnehmung einer Aufgabe, die im &ouml;ffentlichen Interesse liegt oder in Aus&uuml;bung &ouml;ffentlicher Gewalt erfolgt, die dem Verantwortlichen &uuml;bertragen wurde;</p>
<p>(3)&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; aus Gr&uuml;nden des &ouml;ffentlichen Interesses im Bereich der &ouml;ffentlichen Gesundheit gem&auml;&szlig; Art.&nbsp;9 Abs.&nbsp;2 lit.&nbsp;h) und lit. i) DSGVO sowie Art.&nbsp;9 Abs.&nbsp;3 DSGVO;</p>
<p>(4)&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; zur Geltendmachung, Aus&uuml;bung oder Verteidigung von Rechtsanspr&uuml;chen.</p>
<h3>5. Recht auf Unterrichtung</h3>
<p>Haben Sie das Recht auf Berichtigung, L&ouml;schung oder Einschr&auml;nkung der Verarbeitung gegen&uuml;ber dem Verantwortlichen geltend gemacht, ist dieser gem&auml;&szlig; Art. 19 DSGVO verpflichtet, allen Empf&auml;ngern, denen die Sie betreffenden personenbezogenen Daten offengelegt wurden, diese Berichtigung oder L&ouml;schung der Daten oder Einschr&auml;nkung der Verarbeitung mitzuteilen, es sei denn, dies erweist sich als unm&ouml;glich oder ist mit einem unverh&auml;ltnism&auml;&szlig;igen Aufwand verbunden.</p>
<p>Ihnen steht gegen&uuml;ber dem Verantwortlichen das Recht zu, &uuml;ber diese Empf&auml;nger unterrichtet zu werden.</p>
<h3>6. Recht auf Daten&uuml;bertragbarkeit</h3>
<p>Sie haben gem&auml;&szlig; Art. 20 DSGVO das Recht, die Sie betreffenden personenbezogenen Daten, die Sie dem Verantwortlichen bereitgestellt haben, in einem strukturierten, g&auml;ngigen und maschinenlesbaren Format zu erhalten. Au&szlig;erdem haben Sie das Recht diese Daten einem anderen Verantwortlichen ohne Behinderung durch den Verantwortlichen, dem die personenbezogenen Daten bereitgestellt wurden, zu &uuml;bermitteln, sofern</p>
<p>(1)&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; die Verarbeitung auf einer Einwilligung gem. Art.&nbsp;6 Abs.&nbsp;1 lit.&nbsp;a) DSGVO oder Art.&nbsp;9 Abs.&nbsp;2 lit.&nbsp;a) DSGVO oder auf einem Vertrag gem. Art.&nbsp;6 Abs.&nbsp;1 lit.&nbsp;b) DSGVO beruht und</p>
<p>(2)&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; die Verarbeitung mithilfe automatisierter Verfahren erfolgt.</p>
<p>In Aus&uuml;bung dieses Rechts haben Sie ferner das Recht, zu erwirken, dass die Sie betreffenden personenbezogenen Daten direkt von einem Verantwortlichen einem anderen Verantwortlichen &uuml;bermittelt werden, soweit dies technisch machbar ist. Freiheiten und Rechte anderer Personen d&uuml;rfen hierdurch nicht beeintr&auml;chtigt werden.</p>
<p>Das Recht auf Daten&uuml;bertragbarkeit gilt nicht f&uuml;r eine Verarbeitung personenbezogener Daten, die f&uuml;r die Wahrnehmung einer Aufgabe erforderlich ist, die im &ouml;ffentlichen Interesse liegt oder in Aus&uuml;bung &ouml;ffentlicher Gewalt erfolgt, die dem Verantwortlichen &uuml;bertragen wurde.</p>
<h3>7. Recht auf Beschwerde bei einer Aufsichtsbeh&ouml;rde</h3>
<p>Unbeschadet eines anderweitigen verwaltungsrechtlichen oder gerichtlichen Rechtsbehelfs steht Ihnen gem&auml;&szlig; Art. 77 DSGVO das Recht auf Beschwerde bei einer Aufsichtsbeh&ouml;rde, insbesondere in dem Mitgliedstaat Ihres Aufenthaltsorts, Ihres Arbeitsplatzes oder des Orts des mutma&szlig;lichen Versto&szlig;es, zu, wenn Sie der Ansicht sind, dass die Verarbeitung der Sie betreffenden personenbezogenen Daten gegen die DSGVO verst&ouml;&szlig;t.</p>
<p>Die Aufsichtsbeh&ouml;rde, bei der die Beschwerde eingereicht wurde, unterrichtet den Beschwerdef&uuml;hrer &uuml;ber den Stand und die Ergebnisse der Beschwerde einschlie&szlig;lich der M&ouml;glichkeit eines gerichtlichen Rechtsbehelfs nach Art.&nbsp;78 DSGVO.</p>
<h1>E. Datensicherheit</h1>
<p>Zur Sicherung Ihrer Daten verwenden wir innerhalb des Website-Besuchs das verbreitete SSL-Verfahren (Secure Socket Layer) in Verbindung mit der jeweils h&ouml;chsten Verschl&uuml;sselungsstufe, die von Ihrem Browser unterst&uuml;tzt wird. In der Regel handelt es sich dabei um eine 256-Bit Verschl&uuml;sselung. Falls Ihr Browser keine 256-Bit Verschl&uuml;sselung unterst&uuml;tzt, greifen wir stattdessen auf 128-Bit v3 Technologie zur&uuml;ck. Ob eine einzelne Seite unseres Internetauftrittes verschl&uuml;sselt &uuml;bertragen wird, erkennen Sie an der geschlossenen Darstellung des Sch&uuml;ssel- beziehungsweise Schloss-Symbols in der unteren Statusleiste Ihres Browsers.</p>
<p>&nbsp;</p>
			<div class="col-sm-12" style={{height:"80px"}}></div>
		</div>
	</div>
</div>
)
