import React from 'react';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';


export default function ButtonAppBar({childreen}) {
  return (
  
      <AppBar position="static">
        <Toolbar>
         {childreen}
        </Toolbar>
      </AppBar>
   
 
  );
}
