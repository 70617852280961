import { db } from '../firebase'
import * as CATEGORIE_ACTIONS  from '../../store/actions/categorieActions'
import { dispatchFilteredPosts } from '../../store/actions/postActions'
import arrSort from 'array-sort'
import { getPosts } from './postHandler'
import {values} from 'lodash'
import { deletePic } from 'firebase/storage/pictures'
import moment from 'moment'
import { defaultPics } from 'config/config'
const catRef = db.ref('/categories')


export const watchCategories = (state) =>  {
    if(!state)return catRef.off()
    return catRef.on('value', async catSnap => {
        const categories = await catSnap.val()
        if(categories===null) {
            const catArr = []
            return await CATEGORIE_ACTIONS.fetchCategories(catArr)
        }
        const catArr = values(categories)
 
        // dispatch filtered Posts by user selected categories
        return CATEGORIE_ACTIONS.fetchCategories(arrSort(catArr, 'title', ))
    })

}

export const watchUserCategories = (state, userId) =>  {
  //  console.log(userId)
    if(!state)return db.ref(`/user/${userId}`).child('myCategories').off()
    return db.ref(`/users/${userId}/myCategories/`).on('value', async userCatSnap => {
        const userCategories = await userCatSnap.val()
        const postsArr = await getPosts()
       
        if(userCategories===null) {
            const userCatArr = null
            const filteredPosts = null
            dispatchFilteredPosts(filteredPosts)
            return CATEGORIE_ACTIONS.fetchUserCategories(userCatArr)
        }
      
        const userCatArr =  values(userCategories)
        const userCatIdsArr = userCatArr.map(userCat=>userCat.id)
      
        CATEGORIE_ACTIONS.fetchUserCategories(userCatArr)
        const filteredPosts = postsArr.filter(post=>{
           const isInArr = userCatIdsArr.indexOf(post.data.categorieId)!==-1
           return isInArr
        })
        return dispatchFilteredPosts(filteredPosts)
       
    })
}

export const addCategorie = (data, id) => {
    const pushKey = id || catRef.push().key
    // if picURL equeal false set default Cat pic url

    if(!data.picURL && !data.resPicURL &&!data.fileName) {
        data.fileName = defaultPics.categorie.name
        data.picURL = defaultPics.categorie.picURL
        data.resPicURL = defaultPics.categorie.picURL
    }
    const categorie = {
        ...data,
        id: data.id || pushKey,
        timeStamp: moment().format(),
    }


   // const userFilter = db.ref(`/categories/${userId}/myCategories`).child(categorie.id)
   return catRef.child(pushKey).set(categorie)
}

export const getNewCatId = () => {
    return catRef.push().key
}
export const deleteCategorie = (categorie) => {
    const {id, fileName } = categorie
    console.log('FILENAME', categorie)
    if(fileName!==defaultPics.categorie.name) {
        
    deletePic(fileName)
    deletePic('resized-'+fileName)
    }

   return catRef.child(id).remove()
}

export const addUserCategorie = (userId, categorie) => {
    const userFilter = db.ref(`/users/${userId}/myCategories`).child(categorie.id)
    return userFilter.set(categorie)


}

export const deleteUserCategorie = (userId, catId) => {
    const userFilter = db.ref(`/users/${userId}/myCategories`)
    return userFilter.child(catId).remove()
}
