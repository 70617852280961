import React, {useState} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import CardHeader from '@material-ui/core/CardHeader';
import CardMedia from '@material-ui/core/CardMedia';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';
import Collapse from '@material-ui/core/Collapse';
import Avatar from '@material-ui/core/Avatar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import { red } from '@material-ui/core/colors';
//import ShareIcon from '@material-ui/icons/Share';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import CommentIcon from '@material-ui/icons/Comment';
import Badge from '@material-ui/core/Badge'
import DeleteIcon from '@material-ui/icons/Delete'
import moment from 'moment'
import { deletePost } from 'firebase/database/postHandler'
import Button from '@material-ui/core/Button'
import Dialog from 'Components/PopUps/Dialog'
import noPic_post from 'static/noPic_post.jpg'
import { withSnackbar } from 'notistack';


const useStyles = makeStyles(theme => {
  return ({
  root: {
    borderBottom: `2px solid ${theme.palette.divider}` 
  //  maxWidth: 400,
  },
  media: {
    height: 0,
    paddingTop: '56.25%', // 16:9
  },
  expand: {
 
    transform: 'rotate(0deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    transform: 'rotate(180deg)',
  },
  avatar: {
    backgroundColor: red[500],
  },
  header: {
  
  },
  userName: {
    color: theme.palette.primary.dark
  }
})
})

function ListItem({item, style, index, onItemClick, expandedView, children, commentsCounter, isAdmin, userId, enqueueSnackbar}) {
  const classes = useStyles();
  const [ dialog, setDialog ] = useState({isOpen: false, text: '', title: '', buttons: []})

 const handleDeletePost = () => {
    const postId = item.id
    const handleDialog = type => {
      const resetDialog = {
        isOpen: false, title: '', text: '', buttons: []
      }
      if(type==='delete') {
        const message = 'Betrag gelöscht'
        const variant = 'success'
        enqueueSnackbar(message, {
          variant,
          anchorOrigin: {
              vertical: 'bottom',
              horizontal: 'center',
          }, })
        deletePost(postId)

      }
      return setDialog(resetDialog)
    }
   const dialogButtons = [
     <Button onClick={()=>handleDialog('delete')} color='secondary' key={0}>Löschen</Button>,
     <Button onClick={()=>handleDialog('cancel')} color='primary' key={1}>Abbrechen</Button>
   ]
   setDialog({isOpen: true, title:'Post löschen?', text: 'Wollen Sie den Post wirklich löschen', buttons: dialogButtons})
   //
 }

  return (
    <div className={classes.root}>
      <CardHeader
      className={classes.header}
        avatar={
          <Avatar src={item.catResPicURL} aria-label="recipe" className={classes.avatar}>
            {item.categorieName.substring(0,1)}
          </Avatar>
        }
        action={
          isAdmin || userId===item.userId
            ? <IconButton aria-label="settings" onClick={handleDeletePost} >
                <DeleteIcon />
              </IconButton>
            : null
        }
        title={<Typography variant='h6'>{item.categorieName.toUpperCase()}</Typography>}
        subheader={moment(item.date).format('LL')}
      />
      <CardMedia
        className={classes.media}
        image={item.resPicURL || noPic_post}
      />
      <CardContent>
      <Typography className={classes.userName} variant="subtitle1" color="textSecondary" >
         {item.userName}
        </Typography>
        <Typography variant="subtitle1" color="textPrimary">
          {item.title.toUpperCase()}
        </Typography>
        <Typography variant="body2" color="textPrimary" component="p" >
          {item.text}
        </Typography>
      </CardContent>
      <CardActions disableSpacing>
        {!expandedView ?
          <React.Fragment>
            <Typography variant='subtitle2'>Kommentare</Typography>
            <IconButton aria-label="share" onClick={()=>onItemClick(index)}>
              <CommentIcon />
              <Badge 
                badgeContent={commentsCounter}
                color='primary'
                style={{paddingLeft: '6px', paddingBottom: '16px'}}
              />
            </IconButton>
            </React.Fragment>
         : <Button onClick={()=>onItemClick(index)} variant='outlined' color='primary' >Zurück</Button>}
        <IconButton
          className={clsx(classes.expand, {
            [classes.expandOpen]: expandedView,
          })}
          onClick={()=>onItemClick(index)}
          aria-expanded={expandedView}
          aria-label="show more"
        >
          <ExpandMoreIcon />
        </IconButton>
      </CardActions>
      <Collapse in={expandedView} timeout="auto" unmountOnExit>
          {children}
      </Collapse>
      {dialog.isOpen && <Dialog data={dialog} />}
    </div>

  )
}
export default withSnackbar(ListItem)