import React from 'react'
import { getRoleBasedNavigation } from './navigation.cfg'   
import { publicRoutes, privateRoutes, adminRoutes } from './routes'
import INITAL_STATE from './INITAL_STATE'
import actionTypes from './actionTypes'
import firebaseConfig from './firebase.cfg'
import landingPageBG_desktop from '../static/landingPageBG-desktop.jpg'
import {userAccountTxt} from './text'

export const appName = 'BRÜCKENBAUER'
export const appLogo = 'BRÜCKENBAUER'
export const landingPageCFG = {
    title: appName,
    text: 'Herzlich willkommen bei unserer sozialen App, die Generationenen verbindet',
    backgroundImage: landingPageBG_desktop,
    withFooter: true,
}


export const defaultPics = {
    categorie: {
        picURL: "https://firebasestorage.googleapis.com/v0/b/busstop-68aac.appspot.com/o/defaultPics%2FdefaultCategoriePic.jpg?alt=media&token=43075565-5951-4152-b705-bc4574ba89c8",
        name: "defaultCategoriePic.jpg"
    }
}
export const routes = {
    public: {...publicRoutes},
    private: {...privateRoutes},
    admin: {...adminRoutes}
}
export const text = {
    userRegistration: {
       errors: {
            email: 'Geben Sie eine gültige Email Adresse ein',
            username: 'Der Benutzername muss mindestens 6 Zeichen haben',
            firstname: 'Der Vorname muss mindestens 3 Zeichen haben',
            name: 'Ihr Name muss mindestens 3 Zeichen haben',
            homeTown: 'Geben Sie ihren Wohnort ein',
            password: 'Ihr Kennwort muss mindestens 6 Zeichen haben',
            passwordConfirm: 'Ihre Kennwörter stimmen nicht überein. Überprüfen Sie ihre Eingabe',
            wrongCharacter: 'Sie haben ein nicht erlaubtes Sonderzeichen verwendet',
            dataProtectionConfirmed: 'Sie müssen die Datenschutzrichtlinien bestätigen'
       }
    },
    login: {
        errors: {
            'auth/too-many-requests': "Sie haben zu oft das falsche Passwort eingegeben. Ihr Konto wurde gesperrt, versuchen Sie es in ein paar Minuten wieder.",
            'auth/wrong-password': 'Sie haben ein falsches Passwort eingegeben. Bitte versuchen Sie es erneut.',
            'auth/user-not-found': 'Zu dieser Email Adresse existiert kein Benutzerkonto. Überprüfen Sie ihre E-Mail Adresse.',
            'auth/invalid-email' :'E-Mail Adresse hat ein ungültiges Format. Bitte überprüfen Sie ihre E-Mail Adresse',
            default: <span>Es ist ein Fehler aufgetreten. Anmeldung nicht möglich. Bitte wenden Sie sich an den <a href='mailto: support@brückenbauer.org'>Support</a>.</span>
        },

    },
    addPost: {
        errors: {
            selectedCat: 'Wählen Sie eine Kategorie aus',
            title: 'Der Titel muss mindestens 6 Zeichen haben',
            text: 'Der Text muss mindestends 6 Zeichen haben',
            pic: 'Wollen Sie kein Bild hochladen?'
        },
    },
    categories: {
        delete: {
            title: 'Kategorie löschen',
            text: "Wollen Sie die Kategorie wirklich löschen?"
        }
    },
    userAccount: {
        ...userAccountTxt
    },
 
}



const config = {
    appName: 'BRÜCKENBAUER',
    appLogo: 'BRÜCKENBAUER',
    landingPage: routes.LANDING_PAGE,
    navigation: {
        title: 'Menü',
        logo: false,
        getNavigationItems: (role)=>getRoleBasedNavigation(role)
    }, 
    INITAL_STATE: INITAL_STATE,
    routes: routes,
    actionTypes: actionTypes,
    firebase: {
        config: firebaseConfig.DEV
    },
    text: text,

}

// config event handler

export {
    actionTypes,
 }
export default config
