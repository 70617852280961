import { dispatch } from '../store'
import {actionTypes} from './config'
const INIT_STATE = {
    app : {
        isLoading: true,
        popUp: {
            fullScreen: false,
        },
        dialog: {
            isOpen: false,
            title: '',
            text: '',
            buttons: []
        },
    },
    navigation: {
        navigateTo: payload=>dispatch({type: actionTypes.NAVIGATE_TO, payload}),
        active: '/admin/home',
        navState: {
            active: 'list',
            activeTitle: 'Feed Übersicht',
            timeout: '555'},
        fadeState: {
              publicFeed: {
                list: true,
                options: false,
                addPost: false,
                details: false,
              }
        }, 
    },
    user: {
        id: null,
        isLoading: true,
        isAuth: false,
        emailVerified: false,
        role: {
            public: true,
        },
        firebase: null  ,
    },
    categories: {
        data: null,
        filtered: null,
        userCategories: null,
        userCatArr: []
    },
    posts: {
        data: null,
        filtered: null,
    },
    users: {
        data: null,
    }
}

export default INIT_STATE