import _ from 'lodash'

const INITIAL_STATE = {
    data: null,
    filtered: null,
    isLoading: true,
  }
  
  function postsReducer(state = INITIAL_STATE, action) {
      switch(action.type) {
        case 'FETCH_POSTS' : {
          return ({
              ...state,
              data: action.payload===null ? [] : _.sortBy(_.values(action.payload, 'data.date')).reverse(),
          })
        }
        case 'FILTERED_POSTS' : {
          return ({
              ...state,
              filtered: action.payload===null ? null : _.values(_.sortBy(action.payload, 'data.date')),
          })
        }
        case 'FILTER_MYPOSTS' : {
          return ({
              ...state,
              filtered: action.payload===null ? [] : action.payload,
          })
        }        
        default : return state;
      }
    }
    
    export default postsReducer;