import { db } from '../firebase'
import { dispatchPosts } from '../../store/actions/postActions'

const postRef = db.ref('posts')

export const getNewPostId = () => {
    const newPushKey = postRef.push().key
    return newPushKey
}

export const addPost = async (post, userId) => {
  
    const pushKey = postRef.push().key
    // if post.id undefined there ist no pic for the post uploaded
    post.id = post.id===undefined ? pushKey : post.id
    await addToMyPosts(post.id, userId)
    return postRef.child(post.id).child('data').set(post)
}

export const watchPosts = (state) => {
  
    if(!state) return postRef.off()
    return postRef.on('value',async postsSnap=> {
        const posts = await postsSnap.val()
       return dispatchPosts(posts)
    })
}


export const getPosts = async (state) => {
    const postsSnap = await postRef.once('value')
    if(!postsSnap.exists())return []
    const posts = await postsSnap.val()
    return Object.values(posts)
}
export const getPost = async postId => {
    const postSnap = await postRef.child(postId).once('value')
    return postSnap.val()
}

export const deletePost = async (id) => {
    return postRef.child(id).remove()
}

export const addToMyPosts = async (postId, userId) => {
    const myNewPostsArr = []
    const myPostsRef = db.ref(`/users/${userId}/myPosts`)
    const myPostsSnap = await myPostsRef.once('value')
    if(myPostsSnap.exists())
        myNewPostsArr.push(...myPostsSnap.val())
    myNewPostsArr.push(postId)
   return  myPostsRef.set(myNewPostsArr)

}

export const deleteMyPost = async (id, userId) => {
 
    const myPostsRef = db.ref(`/users/${userId}/myPosts`)
    const myPostsSnap = await myPostsRef.once('value')
    const myPostsArr = await myPostsSnap.val()

    if(myPostsArr===null)return console.error('No myPosts available to delete')
    // get the index of the array element in this case postId
    const indexOfEl = myPostsArr.indexOf(id)
    // delete element with index
    myPostsArr.splice(indexOfEl)
 
    return myPostsRef.set(myPostsArr)
 
}