
const INITIAL_STATE = {
    data: null,
    filtered: null,
    userCategories: null,
    userCatArr: []
     
  }
  
  
  function categoriesReducer(state = INITIAL_STATE, action) {
      switch(action.type) {
        case 'FETCH_CATEGORIES' : {
          return ({
              ...state,
              data: action.payload,
             // filtered: action.payload,
          })
        }
        case 'ADD_CATEGORIE' : {
            const newCategories = [...state.filtered]
            newCategories.push(action.payload)
            return ({
                ...state,
                filtered: newCategories,
            })
          }
        case 'REMOVE_CATEGORIE' : {
            const newCategories = [...state.filtered]
            const catToDelete = state.filtered.indexOf(action.payload)
            newCategories.splice(catToDelete, 1)
            return ({
                ...state,
                filtered: newCategories,
            })
          }
          case 'FETCH_USER_CATEGORIES' : {
            const userCatArr = action.payload!==null ? action.payload.map(userCat=> {
              return userCat.id
            })
            : []
            return ({
                ...state,
                userCategories: action.payload,
                userCatArr: userCatArr
            })
          }
        
        
        default : return state;
      }
    }
    
    export default categoriesReducer;