import React from 'react'
import { Route } from 'react-router-dom'
import LoginPage from '../../App/Public/LoginPage'
import LandingPage from '../../App/Public/LandingPage'
import UserRegistration from '../../App/Public/UserRegistration'
import EmailVerificationMessage from '../../App/Public/EmailVerificatonMessage'
import Logout from '../../App/Public/Logout'

export const routes = {
    LOGIN: '/login',
    LANDING_PAGE: '/',
    USER_REGISTRATION: '/user_registration',
    EMAIL_VERIFICATION_MESSAGE: '/email_verification_message',
    PASSWORD_FORGET: '/password_forget',
    DATA_PROTECTION: '/datenschutz',
    FORSEARCH: '/brückenbauer_berchtesgader_land',
    LOGOUT: '/logout'

}

export const PublicRoutes = () => {

    return (
        <React.Fragment>
            <Route exact path={routes.LOGIN} component={() => <LoginPage />} />
            <Route exact path={routes.LANDING_PAGE} component={() => <LandingPage />} />
            <Route exact path={routes.USER_REGISTRATION} component={() => <UserRegistration />} />
            <Route exact path={routes.EMAIL_VERIFICATION_MESSAGE} component={() => <EmailVerificationMessage />} />
            <Route exact path={routes.LOGOUT} component={() => <Logout />} />
        </React.Fragment>
    )
}

export default PublicRoutes
