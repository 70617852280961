import { createMuiTheme, responsiveFontSizes } from '@material-ui/core/styles'
//import purple from '@material-ui/core/colors/purple'
import green from '@material-ui/core/colors/green'
import blue from '@material-ui/core/colors/blue'
//import grey from '@material-ui/core/colors/grey'

let theme = createMuiTheme({
  palette: {
    green: {
      dark: green['900'],
      main: green['500'],
      light: green['100']
    },
    primary: {
      main: '#d12844',
      light: '#f71748',
    },
    secondary: {
      dark: blue[900],
      main: blue[600],
      light: blue[100],
    }
  },
  
  typography: {
    body1: {
      fontSize: "1.3rem"
    }
  },
  drawer: {
    width: '300px',
    height: '100%',
    border: 'none'
    //backgroundColor: grey[100]
  }
})
theme = responsiveFontSizes(theme);

export default theme