import React from 'react'
import { Route } from 'react-router-dom'
import AdminHome from '../../Container/Admin/AdminHome'
import Header from '../../App/Header'
import Footer from '../../App/Footer'
import DefaultPage from '../../Components/Layouts/DefaultPage'
import AppBar from '../../App/AppBar'
import MainNav from '../../App/MainNav'

export const routes = {
    ADMIN_HOME: '/admin/home'
}
export const AdminRoutes = () => {
    return (
        <React.Fragment >
        <Header />
        <AppBar 
            childreen={<MainNav />}
        />
         <DefaultPage >
        <Route exact path={routes.ADMIN_HOME} component={() => <AdminHome />} />
        </DefaultPage>
            <Footer /> 
        </React.Fragment>
    )
}

export default AdminRoutes