import React from 'react'
import eu_flagge from 'static/leader/eu_flagge.png'
import leader_neu from 'static/leader/leader_neu.png'
import wappen_4c from 'static/leader/wappen_4c.png'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import Paper from '@material-ui/core/Paper'

const leaderText = 'Gefördert durch das Bayerische Staatsministerium für Ernährung, Landwirtschaft und Forsten und den Europäischen Landwirtschaftsfonds für die Entwicklung des ländlichen Raums (ELER)'
const LeaderBoard = () => {
    return (
        <Paper style={{marginBottom: '1rem', padding: '1rem', opacity: 0.8}}>
        <Grid container spacing={2} >
            <Grid item xs={4} md={4} >
                <img src={eu_flagge} alt='EU Flagge' style={{width: '94%'}} />
            </Grid>
            <Grid item xs={4} md={4} >
                <img src={wappen_4c} alt='EU Flagge' style={{width: '100%'}} />
            </Grid>
            <Grid item xs={4} md={4} >
                <img src={leader_neu}alt='EU Flagge' style={{width: '60%'}} />
            </Grid>
            <Grid item md={12} style={{padding: '0.8rem'}}>
                <Typography children={leaderText} variant='subtitle1' style={{fontSize: '0.9rem', lineHeight: 1.5}}  />

         
            </Grid>
        </Grid>
        </Paper>
    )
}

export default LeaderBoard
