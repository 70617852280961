import React from 'react'
import {appLogo } from '../config'
import LeaderLogos from 'Components/Leader/LeaderLogosHeader'
import Grid from '@material-ui/core/Grid'

const Header = () => {
    return (
      
        <div id="App-header">
            <Grid container >
                <Grid item md={4} >
                    <span>{appLogo}</span>
                </Grid>
                <Grid item md={8} >
                    <LeaderLogos />
                </Grid>
            </Grid>
           
            

        </div>
     
    )
}
export default Header

