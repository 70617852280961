import React from 'react'
import Typography from '@material-ui/core/Typography'
import withStyles from '@material-ui/styles/withStyles'

const styles = theme => {
    return { 
        root: {
            maxWidth: '680px',
            marginLeft: 'auto',
            marginRight: 'auto',
            padding: `${theme.spacing(2)}px 0 ${theme.spacing(2)}px 0`,
           // border: '1px solid red'
        }, 
    }
}
const DefaultPage = (props) => {
    const { title, children, classes } = props
    return (
        <div id='App-body' className={classes.root}>
            {title && <Typography variant="h4" component="h4" gutterBottom >
                {title || null}
            </Typography>}
                {children}
           
             
    
        </div>
    )
}

export default withStyles(styles)(DefaultPage)