import React from 'react'
import { Route } from 'react-router-dom'
import Header from '../../App/Header'
import Footer from '../../App/Footer'
import DefaultPage from '../../Components/Layouts/DefaultPage'
import AppBar from '../../App/AppBar'
import MainNav from '../../App/MainNav'
import UserAccount from '../../Container/UserAccount'
import AddPost from '../../Container/AddPost'
import PublicFeed from '../../Container/PublicFeed'
import Datenschutz from '../../App/Public/Datenschutz'

export const routes = {
    HOME: '/app/home',
    USER_ACCOUNT: '/app/user_account',
    ADD_POST: '/app/add_post',
    PUBLIC_FEED: '/app/public_feed',
    DATA_PROTECTION: '/app/Datenschutzerklärung'
}

const roles = [
    'admin',
    'appUser',
]


export const PrivateRoutes = ({match, role}) => {
        ('prvate routes')
    return (
        <React.Fragment >
            <Header />
            <AppBar 
                childreen={<MainNav />}
            />
             <DefaultPage >
                {roles.includes(role) && (
                    <React.Fragment >
                        <Route exact path={routes.ADD_POST} component={() => <AddPost />} />
                        <Route exact path={routes.PUBLIC_FEED} component={() => <PublicFeed />} />
                        <Route exact path={routes.USER_ACCOUNT} component={() => <UserAccount />} />
                        <Route exact path={routes.DATA_PROTECTION} component={() => <Datenschutz publicView={false} />} />
                    </React.Fragment>     
                )}
           
            </DefaultPage>
            <Footer /> 
        </React.Fragment>
        
    )
}

export default PrivateRoutes
