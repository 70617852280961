import { db } from '../firebase'
import { dispatchComments } from '../../store/actions/commentActions'
import moment from 'moment'

const commentsRef = db.ref('/comments')

export const watchPostComments = (on, postId) => {
  
    if(!on)return commentsRef.off()
    commentsRef.child(postId).on('value', async snap => {
        const comments = await snap.val()
        return dispatchComments(comments)
    })
}

export const getPostComments = async postId => {
    const commentsSnap = await commentsRef.child(postId).once('value')
    return commentsSnap.val()
}

export const addComment = (userId, userName, postId, comment) => {
    const newCommentsPushKey = commentsRef.push().key
    const newComment = {
        timeStamp: moment().format(),
        userId,
        userName,
        comment,
        id: newCommentsPushKey
    }
    
    commentsRef.child(`${postId}/${newCommentsPushKey}/`).set(newComment)
    db.ref(`/users/${userId}/myComments/${postId}/${newCommentsPushKey}/`).set({id: newCommentsPushKey, postId})
    return db.ref(`/posts/${postId}/comments/${newCommentsPushKey}/`).set({id: newCommentsPushKey})
}

export const deleteComment = (userId, postId, commentId) => {
    commentsRef.child(`${postId}/${commentId}/`).remove()
    db.ref(`/users/${userId}/myComments/${postId}/${commentId}/`).remove()
    return db.ref(`/posts/${postId}/comments/${commentId}/`).remove()
}

export const deletePostComments = (postId) => {
    commentsRef.child(postId).remove()

}