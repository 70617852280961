import {storage} from '../firebase'
const storageRef = storage.ref()

const getPicURL = (pic, callback) => {
   // var gsReference = storage.refFromURL('gs://busstop-68aac.appspot.com/resized-Albi-StateDiag-0.7.jpg ')
    return storageRef.child(pic).getDownloadURL()
    .then(function(url) {
        if(callback) callback(url)
        return url
      })

}

const picUpload = (picture, callback, picName) => {

    if(typeof(picture)==='undefined') return 'Error - no Pic Loaded'

    let uploadTask = storageRef.child(`${picName}`).put(picture)

    return uploadTask.on('state_changed', function(snapshot){
        // Observe state change events such as progress, pause, and resume
        // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
        let progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
     
        switch (snapshot.state) {
          case 'paused': // or 'paused'
            //console.log('Upload is paused');
           // callback('loadingProgress', 'Pause')
            break;
          case 'running': // or 'running'
        
           // callback('loadingProgress', 'Bild wird hochgeladen')
            break;
            default : return null
        }
        callback('loadingProgress', progress)
      }, function(error) {
        // Handle unsuccessful uploads
        console.error('File upload error - unsuccessful upload!')
      }, function() {
        // Handle successful uploads on complete
        // For instance, get the download URL: https://firebasestorage.googleapis.com/...
        return uploadTask.snapshot.ref.getDownloadURL().then(function(downloadURL) {
          callback('picURL', downloadURL) // callback ist setStateByPropKey
            return downloadURL;
        });
     


      });
}

const deletePic = name => {
    return storageRef.child(name).delete()
    .then(function() {
  
    }).catch(function(error) {
      console.error('No PIC to Delete')
    });
  

}

export {
    picUpload,
    getPicURL,
    deletePic,
}