import React from 'react'
import ExpensionPanel from 'Components/ExpensionPanels/ExpensionPanel'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Checkbox from '@material-ui/core/Checkbox'
import { addUserCategorie, deleteUserCategorie } from 'firebase/database/categorieHandler'
import { makeStyles } from '@material-ui/core/styles';


const useStyles = makeStyles(theme => ({
    root: {
        backgroundColor: theme.palette.grey['A400']
    }
}))
const Filter = ({data, userId, userCategories}) => {
    const classes = useStyles()
    if(data===null)return <div>Keine Daten zum anzeigen vorhanden.....</div>
    const renderCheckboxContainer = () => {
        const handleChange = (checked, item) => {
          
            if(checked) addUserCategorie(userId, item)
            if(!checked) deleteUserCategorie(userId, item.id)            
        }

        return <div>{data.map((item, index) => {
            
            const isChecked = userCategories.findIndex(userCat=>userCat.id===item.id)!==-1
            return( 
                <div key={index} style={{display: 'inline-block'}} >
                <FormControlLabel
             
               // style={{...checkboxStyle, ...checkboxStyleActiv}}
                control={
                    <Checkbox
                        checked={isChecked}
                        onChange={(e)=>handleChange(e.target.checked, item)}
                        value="checked"
                        color="primary"
                    />
                }
                    label={item.title}
                />
                </div>
            )})}
            </div>
    }
    const filterTitle = <span>
                            <span>FILTER</span>
                           {userCategories.length>0
                                ? <span style={{padding: '0 4px 0 4px', borderRadius: '4px', marginLeft: '8px', color: 'white', backgroundColor: '#d12844'}}>aktiv</span> 
                                : null}
                        </span>
    return (
        <div style={{position: 'absolute', top: '0', zIndex:'999', maxWidth: '664px', width: '100%'}}>
            <ExpensionPanel className={classes.root} title={filterTitle} children={renderCheckboxContainer()}/>
        </div>
    )
}




export default Filter
