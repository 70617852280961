import React from 'react'
import Button from '@material-ui/core/Button'
import { useHistory } from 'react-router-dom'
import { privateRoutes } from 'config/routes'
const Footer = () => {
    const history = useHistory()
    const hanldeOnButtonClick = () => {
        history.push(privateRoutes.DATA_PROTECTION)
    }
    return (
        <div id='App-footer' style={{padding: '8px'}}>
            <Button
                onClick={hanldeOnButtonClick}
                variant='outlined'
                color='primary'
                size='small' 

            >
                Datenschutzerklärung
            </Button>
            <a style={{width: '100%', textDecoration: 'none'}} href='mailto:support@brückenbauer.org'>
                    <Button
                        variant='outlined'
                        color='primary'
                        size='small'
                        fullWidth 
                >
                Kontakt
            </Button>
            </a>
        </div>
    )
}

export default Footer