import React, { Component } from 'react'
import { watchPostComments, deleteComment } from '../firebase/database/commentsHandler'
import { connect } from 'react-redux'
import { withStyles } from '@material-ui/core/styles'
import {compose} from 'recompose'
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';
import Button from '@material-ui/core/Button'
import moment from 'moment'
import Dialog from '../Components/PopUps/Dialog'

const styles = theme => {
  //  console.log(theme)
return ({
    list: {
        'paddingRight': theme.spacing(4),
        'paddingLeft': theme.spacing(4),
        paddingBottom: theme.spacing(4),
    },
    listItem: {
        padding: theme.spacing(1),
        listStyle: 'none',
        marginBottom: theme.spacing(1),
        minHeight: '60px'
    },
    comment: {
        backgroundColor: theme.palette.grey[100],
        borderRadius: theme.spacing(4),
        paddingLeft: theme.spacing(1),
        paddingRight: theme.spacing(2),
        paddingBottom: theme.spacing(2),
        paddingTop: theme.spacing(1),
        overflow: 'hidden'
    },
    commentHeader : {
        ...theme.typography.body2,
        fontWeight: 'bold',
    },
    commentBody: {
        ...theme.typography.subtitle1,
    },
    commentFooter: {
        ...theme.typography.subtitle2,
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2),
    }
})}


class CommentList extends Component {
    constructor() {
        super()
        this.state = {
            dialog: {
                isOpen: false,
                text: '',
                title: '',
                buttons: []
            }
        }
    }
    componentDidMount() {
        watchPostComments(true, this.props.postId)
    }

    handleDeleteComment = (comment) => {

        const handleDialog = (state) => {
            if(state==='confirmed') {
                deleteComment(comment.userId, this.props.postId, comment.id)
            }
            const resetDialog = {
                isOpen: false,
                text: '',
                title: '',
                buttons: []
            }
            return this.setState({dialog: resetDialog})
        }
     
        const deleteConfirmDialog = {
            isOpen: true,
            title: "Kommentar Löschen?",
            text: 'Wollen Sie den Kommentar wirklich löschen',
            buttons: [<Button onClick={()=>handleDialog('confirmed')} color='secondary' key={1}><DeleteIcon />Löschen</Button>, <Button onClick={()=>handleDialog('canceld')} color='primary' key={2}>Abbrechen</Button>]
        }
        return this.setState({dialog: deleteConfirmDialog})
 
    }
    render () {
        const {classes, comments, userId, isAdmin } = this.props
        console.log(isAdmin)
        if(comments===null)return <div>Loading.....</div>
        if(comments.length===0)return <div style={{padding: '8px 8px 48px 8px'}}>Noch keine Kommentare vorhanden</div>
        
        return (
            <ul className={classes.list}>
               {comments.map(comment=> {
               
                const delButton =  userId===comment.userId || isAdmin
                    ?   <span style={{position: 'absolute', right: '4px'}}>
                            <IconButton aria-label="delete" onClick={()=>this.handleDeleteComment(comment)}><DeleteIcon /></IconButton>
                        </span>
                    :   null
                   return (
                        <li key={comment.id} className={classes.listItem}>
                            <div className={classes.comment}>
                                <div className={classes.commentHeader}>
                                    <span>{comment.userName}</span>
                                    {delButton}
                                </div>
                                <div className={classes.commentBody}>
                                    {comment.comment}
                                </div>
                                
                            </div>
                            <div className={classes.commentFooter}>
                                {moment(comment.timeStamp).fromNow()} am  {moment(comment.timeStamp).format('L')}     
                            </div>
                          
                        </li>
                   )})
               }
               {<Dialog data={this.state.dialog} />}
            </ul>
        )
    }
}



const mapStateToProps = state => ({
    comments: state.comments.data,
    userId: state.user.id,
    isAdmin: state.user.role.admin
})

export default compose(
        connect(mapStateToProps),
        withStyles(styles)
)(CommentList)
