import React from 'react'
import { listFilter } from 'js/filter'
import Input from '@material-ui/core/Input';
import SearchIcon from '@material-ui/icons/Search'
import CloseIcon from '@material-ui/icons/Close'
import IconButton from '@material-ui/core/IconButton'
// Property needs
// initList = array of Objects
// callback = function with filtered array - callback
// counter = length of filteredList parent Component - filter not activ needs null
// filterProps = Object props of the initList for filtering
// filterActive true or false

const InputFilter = ({ initList, callback, counter, filterProps, filterActive, propKeys, label }) => {
   //console.log(initList, callback, counter, filterProps, filterActive

    const filterList = (event) => {
        event.preventDefault()
        if(event.target.filterInput.value ==='' || initList.length===0){
            callback(null)
            return null 
        }
        const filter = event.target.filterInput.value
        const filteredArr = listFilter(initList, filter, filterProps, propKeys)
        callback(filteredArr)
    }
    
    const resetInput = () => { 
        document.getElementById('filterInput').value=''
        callback(null)
   
    }
    return (
        
        <form onSubmit={(e)=>filterList(e)}>
            <Input
                color='primary'
                id='filterInput'
                name='filterInput'
                style={{marginBottom: '16px', marginRight: '8px'}}
                placeholder="Suchen"
                //className={classes.input}
                inputProps={{
                    'aria-label': 'Description',
                }}
                autoFocus

            />
            <IconButton 
                type='submit'
                variant='contained'
                color='primary'
                size='small' 
            >
                <SearchIcon color={filterActive ? 'secondary' : 'action'} />
            </IconButton>
            
            {filterActive
                    ? <span style={counter===0 ? {color: 'red', margin: '0 8px 0 8px'} : {margin: '0 8px 0 8px'}}>
                            <IconButton style={{marginRight: '2px'}} size='small' onClick={resetInput}><CloseIcon color='secondary' /></IconButton>
                        <span style={{margin: '0 16px 0 16px'}}>{counter} Treiffer!</span>
                    </span> 
                    : <span style={{margin: '0 16px 0 16px'}}>{initList.length} {label || 'Datensätze'}</span>
            }
        </form>
    )
}

export default InputFilter