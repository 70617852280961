import { applyMiddleware, createStore } from 'redux';
import rootReducer from './reducers';
import thunk from 'redux-thunk'
import config from '../config'
// alt vor middlewwareconst store = createStore(rootReducer);
import { composeWithDevTools } from 'redux-devtools-extension'; //redux dev tool extention


const store = createStore(rootReducer, config.INITAL_STATE, composeWithDevTools(
    applyMiddleware(thunk),

))
export const dispatch = store.dispatch
export default store