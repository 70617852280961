const actionTypes = {
    NAVIGATE_TO: 'NAVIGATE_TO',
    DISPATCH_AUTH_USER: 'DISPATCH_AUTH_USER',
    DISPATCH_NON_AUTH_USER: 'DISPATCH_NON_AUTH_USER',
    USER_EMAIL_IS_VERIFIED: 'USER_EMAIL_IS_VERIFIED',
    APP_IS_LOADING: 'APP_IS_LOADING',
    FULLSCREEN_POPUP: 'FULLSCREEN_POPUP',
    FETCH_COMMENTS: 'FETCH_COMMENTS',
    DIALOG: 'DIALOG',
    DISPATCH_USERS: 'DISPATCH_USERS',
}
export default actionTypes