import { routes as publicRoutes } from './Routes/PublicRoutes'
import { routes as privateRoutes } from './Routes/PrivateRoutes'
import { routes as adminRoutes } from './Routes/AdminRoutes'

export {
    publicRoutes,
    privateRoutes,
    adminRoutes,
}

export default {
    ...publicRoutes,
    ...privateRoutes,
    ...adminRoutes,
}