import React from 'react'
import { Grid, Avatar, IconButton } from '@material-ui/core'
import Typography from '@material-ui/core/Typography'
import DeleteIcon from '@material-ui/icons/Delete'
import { deleteCategorie } from 'firebase/database/categorieHandler'
import { useSnackbar } from 'notistack'
import { Button } from '@material-ui/core'
import { text } from 'config/'



const CategorieList = ({items, handleDialog}) => {
    const { enqueueSnackbar } = useSnackbar();

  

    const handleDeleteCategorie = item => {
        // prepare data for dialog confirm and cancel
        const dialogButtons = [
            <Button variant='contained' color='primary' onClick={()=>dialogInput(true)} key={1} >Löschen</Button>,
            <Button variant='contained' onClick={()=>dialogInput(false)}key={2}>Abbrechen</Button>
        ]
        const resetDialog = { isOpen: false, title: '', text: '', buttons: []}
        const dialogInput = state => {
            if(state) {
                const message = 'Kategorie gelöscht'
                const variant = 'success'
                deleteCategorie(item)
                enqueueSnackbar(message, {
                    variant,
                    anchorOrigin: {
                        vertical: 'bottom',
                        horizontal: 'center',
                    },
                })
               
                handleDialog(resetDialog)
            } else {
                
                handleDialog(resetDialog)
            }
        } 
        handleDialog({isOpen: true, title: text.categories.delete.title, text: text.categories.delete.text, buttons: dialogButtons})       
       
    }
    return (
        <Grid container spacing={1}>
            {Object.values(items).map(item=>
           
              <Grid item md={12} key={item.id} container alignItems="center" justify='space-between' style={{borderBottom: '1px solid #d12844'}}>
                    <Grid item sm={2} style={{padding: '8px 0 8px 0'}} >
                        <Avatar src={item.resPicURL} alt={item.title}/>
                    </Grid>
                    <Grid item sm={8} >
                       <Typography variant='body1'> {item.title} </Typography>
                    </Grid>
                    <Grid item sm={2}>
                        <IconButton onClick={()=>handleDeleteCategorie(item)}>
                            <DeleteIcon />
                        </IconButton>
                    </Grid>

                </Grid>      
            )}
        </Grid>
    )
}


 

export default CategorieList
