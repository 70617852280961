import React from 'react'
import VirtualList from 'react-virtual-list'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import {withStyles} from '@material-ui/core/styles'
import Hidden from '@material-ui/core/Hidden'
import Divider from '@material-ui/core/Divider'
import moment from 'moment'

const styles = theme => ({
     header: {
          backgroundColor: theme.palette.primary.main,
          [theme.breakpoints.down('sm')]: {
               display: 'none',

             },

     }
})

const MyList = ({ virtual, itemHeight, renderProps, classes, buttons, headerProps, listItemToHide }) => {
    
     const renderHeader = () => {
          return (
               <Grid className={classes.header} style={{marginBottom: '8px', padding: '8px', color: 'white', borderRadius: '8px 8px 0 0'}} container>
               
                         {headerProps.map((headProp, index) => {
                              return (
                                   <Grid key={index} item xs={4} sm={4}>
                                        <Typography variant='subtitle2' color='inherit'>
                                             {headProp}
                                        </Typography>
                                   </Grid>
                              )
                         })}
               </Grid>
          )
     }
   
     return (
          <div>
               {renderHeader()}
               <div style={virtual.style}>
                    {virtual.items.map((_item, index) => {
                         const item = _item
                     
                         if(item==='undefined') return null
                         return (
                              <ListItem 
                                   buttons={buttons}
                                   itemHeight={itemHeight}
                                   key={index}
                                   item={item}
                                   classes={classes}
                                   renderProps={renderProps}
                                   index={index}
                                   listItemToHide={listItemToHide}
                              />  
                    )})}
               </div>
          </div>
     )
};

const ListItem = ({itemHeight, item, classes, renderProps, buttons, listItemToHide}) => {
 
     if(typeof(item.profile)==='undefined') return null
     const renderButtons = () => {
          if(buttons===undefined) return null
          return (
               <Grid style={{maxHeight: itemHeight}} item xs={4} sm={4}>
                    {buttons(item)}
               </Grid>
          )
     }
     //if(item.extId===listItemToHide)return null
    return (
       <div>
         <Grid style={{height: itemHeight, padding: '8px'}} container >
              {renderProps.map((renderProp, index) => {
                   let renderItem = item.profile[renderProp]
              
                    if(renderProp ==='timeStamp' || renderProp==='date')
                         renderItem = moment(renderItem).formated('LLLL')
               return index>=1
                    ?<Hidden key={index} smDown>
                         <ItemProp itemHeight={itemHeight} key={index} itemProp={renderItem} />
                    </Hidden>
                    :<ItemProp itemHeight={itemHeight} key={index} itemProp={renderItem} />
              })}
            
              { buttons===undefined ? null : renderButtons() }
           
            
         </Grid>
         <Divider />
         </div>
    )
}

const ItemProp = ({itemProp}) => {
     
    return (
         <Grid style={{overflow: 'hidden'}} item xs={4} sm={4}>
               <Typography variant='body2' style={{fontSize: '0.8em'}}>
                    {itemProp}
               </Typography>
         </Grid>

    )
}

const List = VirtualList()(MyList)

     export default withStyles(styles)(List)