import React, { Component } from 'react'
import VirtualList from 'Components/Lists/VirtualLists/VirtualList'
import { connect} from 'react-redux'
import { watchUsers, deleteUser, disableUser } from 'firebase/database/usersHandler'
import Typography from '@material-ui/core/Typography'
import InputFilter from 'Components/Inputs/InputFilter'
import IconButton from '@material-ui/core/IconButton'
import DeleteIcon from '@material-ui/icons/Delete'
import UserDisableIcon from '@material-ui/icons/PersonAddDisabled';
import { compose } from 'recompose'
import { withSnackbar } from 'notistack'

export class AdminHome extends Component {
    constructor (props) {
        super(props)
        this.state = {
            filteredUsers: null,
            filterActive: false,
        }

        this.handleFilteredUsers = this.handleFilteredUsers.bind(this)
    }

    componentDidMount() {
        watchUsers(true)

    }
    componentWillUnmount() {
        watchUsers(false)
    }

    handleDeleteUser = user => {
        const deleteUserConfirm = window.confirm('Benutzer löschen?')
        if(deleteUserConfirm!==null) return deleteUser(user)
    }

    handleDisableUser = user => {
        const disableUserConfirm = window.confirm('Benutzer deaktivieren?')
        if(disableUserConfirm !==null) return disableUser(user)
    }


    createButtons = (item) =>
        <div>
            <IconButton 
                style={{padding: '0 4px 8 4px'}} 
                size='small' onClick={()=>this.handleDeleteUser(item)}
            >
                    <DeleteIcon/>
            </IconButton>
            {item.disabled
                ? <span style={{backgroundColor: '#d12844', color:'white', fontSize:'0.85em', padding: '4px', borderRadius: '2px '}}>deativiert</span>
                : <IconButton 
                style={{padding: '0 4px 8 4px'}}
                size='small' onClick={()=>this.handleDisableUser(item)}
            >
                <UserDisableIcon/>
            </IconButton>}
        </div>


    handleFilteredUsers(users) {
        if(users===null){
            return this.setState({filterActive: false, filteredUsers: null})
        }

        this.setState({filteredUsers: users, filterActive: true})
    }
    
    render() {
        if(this.props.users===null)return <div>Keien Daten vorhanden.....</div>
        return (
            <div style={{padding: '4px'}}>
                <Typography variant='h6' >Benutzerliste: </Typography>
                <section style={{padding: '4px'}} >
                    <InputFilter
                        label='Benutzer' 
                        initList={this.props.users}
                        callback={this.handleFilteredUsers}
                        counter={this.state.filteredUsers!==null ? this.state.filteredUsers.length : this.props.users.length}
                        filterActive={this.state.filterActive}
                        propKeys={['profile']}
                        filterProps= {['username', 'email']}
                    />
                </section>

                    <VirtualList 
                        items={this.state.filteredUsers===null ? this.props.users : this.state.filteredUsers}
                        itemHeight={36}
                        headerProps={['Benutzername', 'E-Mail', 'Freischalten']}
                        renderProps={['username', 'email']}
                        buttons= {this.createButtons}
                        listItemToHide={this.state.hideListItem}
                    />

            </div>
        )
    }
}

const mapStateToProps = state => ({
    users: state.users.data,
})

export default compose(
    connect(
        mapStateToProps),
        withSnackbar
    )(AdminHome)
