import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import BottomNavigation from '@material-ui/core/BottomNavigation';
import BottomNavigationAction from '@material-ui/core/BottomNavigationAction';
import FavoriteIcon from '@material-ui/icons/Favorite'
import { connect } from 'react-redux'
import { useHistory } from "react-router-dom";

const useStyles = makeStyles(theme => {return ({
  root: {
    minWidth: 600,
    marginLeft: 'auto',
    marginRight: 'auto',
    backgroundColor: 'inherit',

  },
  navItem: {
      color: 'yellow',
     
  },

})});

const SimpleBottomNavigation = ({ navItems, showLabels, navigation, dispatch }) => {
  const classes = useStyles();
  const [value, setValue] = useState(navigation.active);
  const history = useHistory()
  
  return (
    <BottomNavigation
      value={value}
      onChange={(event, newValue) => {
      setValue(newValue);
      history.push(newValue)
      navigation.navigateTo(newValue)
      }}
      showLabels={showLabels || true}
      className={classes.root}
    >
   
      {navItems.map(navItem => {
          if(navItem.title==='Divider')return null
          const NavIcon = navItem.icon || <FavoriteIcon />       

          return  <BottomNavigationAction
                      key={navItem.title}
                      style={navItem.styles || {}} value={navItem.path || '/home'}
                      className={classes.navItem}
                      label={navItem.title || 'Home'}
                      icon={<NavIcon />}

                      />
      })}
 
    </BottomNavigation>
  );
}

const mapStateToProps = state => ({
  navigation: state.navigation
})


export default connect(mapStateToProps)(SimpleBottomNavigation)