import React, { Component } from 'react'
import Typography from '@material-ui/core/Typography'
import { watchCategories } from 'firebase/database/categorieHandler'
import { connect } from 'react-redux'
import {withStyles} from '@material-ui/core/styles'
import { compose } from 'recompose'
import AddIcon from '@material-ui/icons/Add';
import IconButton from '@material-ui/core/IconButton'
import AddCategorie from './AddCategorie'
import CategorieList from './CategorieList'
import ExpandLessIcon from '@material-ui/icons/ExpandLess'
import Dialog from 'Components/PopUps/Dialog'


const styles = theme => ({

    addIconBtn: {
        backgroundColor: theme.palette.primary.main
    },
    picUploadButton: {
        marginBottom: theme.spacing(2)
    }

})

class CategorieMgm extends Component  {
    constructor () {
        super()
        this.state = {
            showAddCategorie: false,
            dialog: {
                isOpen: false,
                title: '',
                text: '',
                buttons: [],
            }
        }
    }
    
    componentDidMount() {
        watchCategories(true)
    }
    componentWillUnmount() {
        watchCategories(false)
    }

    handleAddCategorie = () => {
        return this.setState({
            showAddCategorie: !this.state.showAddCategorie
        })

    }
    handleDialog = data => {
        this.setState({dialog: data})
    }
    
    render ()  {
        const { classes } = this.props
     //  console.log(this.props.categories)
        if(this.props.categories===null)return <div>Loading....</div>
        return (
            <div style={{padding: '8px'}}>
                <div>
                    <Typography variant='h6' >Kategorien verwalten </Typography>
                    <div style={{textAlign: 'right', marginBottom: '8px'}}>
                        <IconButton onClick={this.handleAddCategorie} className={classes.addIconBtn}  >   
                            {!this.state.showAddCategorie ? <AddIcon /> : <ExpandLessIcon />}
                        </IconButton>
                    </div>
                </div>
                {this.state.showAddCategorie && <AddCategorie handleDialog={this.handleDialog}/>}
                <div>
                    {this.props.categories.length===0 && <div>Keine Kategorien zum anzeigen vorhanden</div>}
                    <CategorieList items={Object.values(this.props.categories)} handleDialog={this.handleDialog}/>
                </div>
                {this.state.dialog.isOpen && <Dialog data={this.state.dialog} />}
            </div>
    
        )
    }
}


const mapStateToProps = state => ({
    categories: state.categories.data
})

export default compose(
    connect(mapStateToProps),
    withStyles(styles)
)(CategorieMgm)
