import { combineReducers } from 'redux'
import navigationReducer from './navigationReducer'
import userReducer from './userReducer'
import appReducer from './appReducer'
import categoriesReducer from './categoriesReducer'
import postReducer from './postsReducer'
import commentsReducer from './commentsReducer'
import usersReducer from './usersReducer'

const rootReducer = combineReducers({
        app: appReducer,
        navigation: navigationReducer,
        user: userReducer,
        categories: categoriesReducer,
        posts: postReducer,
        comments: commentsReducer,
        users: usersReducer,
});

export default rootReducer;