import React from 'react'
import { landingPageCFG } from '../../config/config'
import Button from '@material-ui/core/Button'
import { makeStyles } from '@material-ui/core'
import Typography from '@material-ui/core/Typography'
import bgImage from '../../static/landingPageBG-desktop.jpg'
import { useHistory } from 'react-router-dom'
import { routes } from '../../config/config'
const useStyles = makeStyles(theme => {

    return ({
        root: {
            width: '100%',
            overflow: 'hidden',
            backgroundImage: `url(${bgImage})`,
            height: '100vh',
            backgroundPosition: 'center',
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'cover',
        },
        wrapper: {
            height: '100vh',
            textAlign: 'center',
            margin: '0 auto 0 auto',
            maxWidth: '680px',
            paddingLeft: theme.spacing(2),
            paddingRight: theme.spacing(2),
            overflow: 'hidden'
           
          
          //  minHeight: '100vh',
            //padding: theme.spacing(),
        },
        marginTop: {
            height: '10vh'
        },
        buttonWrapper: {
            padding: `0 ${theme.spacing(1)} 0 ${theme.spacing(1)}`
            
        },
        footer: {
            position: 'absolute',
            textAlign: 'center',
            padding: '1em',
            bottom: 0,
            left: 0,
            width: '100vmin',
        },
        agbLink: {
            color: 'white',
            ...theme.typography.button
            
        }   
    })
})


const LandingPage = () => {
    const history = useHistory()
    const classes = useStyles()
    const onLoginClick = () => {
        history.push(routes.public.LOGIN)
    }
    const onRegistrationClick = () => {
   
        history.push(routes.public.USER_REGISTRATION)
    }

    return (
            <React.Fragment>
                <section>
                    <Typography 
                        variant='h6'
                        gutterBottom
                        paragraph
                        children={landingPageCFG.text
                                    ? landingPageCFG.text
                                    : <span>
                                        <p>Willkommen auf unserer App</p><p>Sie haben bereits ein Benutzerkonto, dann melden Sie sich an.</p>
                                        <p>Noch kein Benutzerkonto, dann regisriren Sie sich</p>
                                    </span>
                                } 
                        
                                    />
                </section>
                <section className={classes.buttonWrapper}>
                        <Button 
                            onClick={onLoginClick}
                            color='primary'
                            variant='outlined'
                            fullWidth
                        >
                            Anmelden
                        </Button>
                </section>
                <br />
                <section className={classes.buttonWrapper}>
                        <Button 
                            onClick={onRegistrationClick}
                            color='secondary'
                            variant='outlined'
                            fullWidth
                        >
                            Registrierung
                        </Button>
                </section>
         
            <div className={classes.footer}>
        
            </div>
        </React.Fragment>

    )
}

export default LandingPage