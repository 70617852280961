import React, { Component } from 'react'
import { compose } from 'recompose'
import { withStyles } from '@material-ui/core/styles'
import { connect } from  'react-redux'
import { text } from 'config/config'
//import PropTypes from 'prop-types'
import TextField from '@material-ui/core/TextField';
//import Card from '@material-ui/core/Card'
import Fade from '@material-ui/core/Fade'
import Typography from '@material-ui/core/Typography'
//import Loading from 'Components/Shared/Loading/Spinner'
import Button from '@material-ui/core/Button';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
//import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import FilledInput from '@material-ui/core/FilledInput';
import CamRole from '@material-ui/icons/CameraAlt'
import moment from 'moment'
import Spinner from '../Components/Loading/Spinner'
//import { fullScreenPopUp } from '../store/actions/appActions'
import { watchCategories } from '../firebase/database/categorieHandler'
import { picUpload, getPicURL } from '../firebase/storage/pictures'
import { addPost, getNewPostId } from '../firebase/database/postHandler'
import FormHelperText from '@material-ui/core/FormHelperText'
import { withRouter } from 'react-router-dom'
import { privateRoutes } from 'config/routes'
import { withSnackbar } from 'notistack'

const styles = theme => ({
    root: {
        maxWidth: '480px',
        marginLeft: 'auto',
        marginRight: 'auto',
        padding: theme.spacing(1)

    },
    title: {
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2),
        textAlign: 'center',
    },
    formControl: {
        display: 'block',
        width: '100%',
    },
    picWrapper : {
        minHeight: '260px',
        maxHeight: '480px',
        border: '1px solid grey',
        bakcgroundColor: 'gray',
        overflow: 'hidden',
       // marginLeft: 'auto',
       // marginRight: 'auto',
       marginTop: theme.spacing(2),
       marginBottom: theme.spacing(2)
    },
    picUploadButton: {
        marginBottom: theme.spacing(2)
    }
})

class AddPost extends Component {
    constructor(props) {
        super(props);
        // Don't call this.setState() here!
        this.state = { 
            formValues: {
                selectedCat: '',
                catName: '',
                title: '',
                text: '',
                picURL: null,
            },
            errors: {...text.addPost.errors}, 
            selectedFile: null,
            picVisable: false,
            picName: null,
            saveButtonDisabled: true,
            formValide: false, 
            loadingStarts: false,
            loadingProgress: 0,
            showErrorMessage: false,
        };
        //this.handleClick = this.handleClick.bind(this);
      }
    componentDidMount () {
        watchCategories(true)  
    }
    componentWillUnmount () {
        watchCategories(false)
    }

    setStateByPropKey = (propKey, value) => {
      
        if(propKey==='loadingProgress' || propKey==='loadingStarts') {
            if(value===100)this.setState({errors: {...this.state.errors, pic: null}})
            return this.setState({
                [propKey]: value 
            })
        }
        const isValide = this.formValidation(propKey, value)
        if(isValide) {
            return this.setState({
                formValues: {
                    ...this.state.formValues,
                    [propKey]: value,
                },
                errors: {
                    ...this.state.errors,
                    [propKey]: ''
                },
            })
        }
   
        return this.setState({    
          errors:{ ...this.state.errors, [propKey]: text.addPost.errors[propKey]}
        })
    }
    focusTitle = () => {
        this.titleInput.current.focus()
    }

  
    formValidation = (propKey, value) => {
  
        let isValide = false
        switch (propKey) {
            case 'selectedCat':
                isValide = value!==''
                break
            case 'title':
                isValide = value.length>=6
                break
            case 'text':
                isValide = value.length>=12
                break
            default:
                return true
        }
        return isValide

       
    }
   
    saveForm = async () => {
       
        const picURLCallback = (url) => {
            this.setState({resPicURL: url})
        }
     
        const { title, text, selectedCat } = this.state.formValues
    
        if(!title || !text || !selectedCat) {
            console.error('Invalide Input')
            return this.setState({showErrorMsg: true})

        }
        
        let picName = this.state.picName
        if(picName!==null) {
        picName = this.state.picName
        await getPicURL('resized-'+ picName, picURLCallback)
        }
        const date = moment().format()
        const newPost = {
            id: this.state.id,
            categorieId: this.state.formValues.selectedCat.id,
            catResPicURL: this.state.formValues.selectedCat.resPicURL,
            categorieName: this.state.formValues.selectedCat.title,
            title: this.state.formValues.title,
            text: this.state.formValues.text,
            userId: this.props.user.id,
            userName: this.props.user.firebase.profile.username,
            date: date,
            picName: picName || false,
            orgPicURL: this.state.formValues.picURL || false,
            resPicURL: this.state.resPicURL || false
        }
        const message = 'Beitrag gespeichert'
        const variant = 'success'
        this.props.enqueueSnackbar(message, {
            variant,
            anchorOrigin: {
                vertical: 'bottom',
                horizontal: 'center',
            }, })
        
        
        addPost(newPost, this.props.user.id)
   
        this.props.dispatch({type: 'NAVIGATE_TO', payload: 'list'})
       return this.props.history.push(privateRoutes.PUBLIC_FEED)

    }

    imageUploadHandler = async (e) => {
        e.preventDefault()   
        const selectedFile = e.target.files[0]
        const myPicURL = URL.createObjectURL(selectedFile)
        // use local image after loading vs downloading new from store
 
        this.setState({selectedImageURL: myPicURL})
        const newPostId = getNewPostId()
     
        const orgFileName = selectedFile.name
        // get the file extention of the org uploaded
        const fileExtention = orgFileName.substring((orgFileName.length-4), orgFileName.length)
        const newFileName = newPostId+fileExtention
        // local file name
        this.setState({picName: newFileName, id: newPostId})
        
        picUpload(selectedFile, this.setStateByPropKey, newFileName)
        return this.setState({loadingStarts: true})
    } 
    
   
    render () {
        
        const { classes } = this.props
        const { setStateByPropKey } = this
        const { selectedCat,  } = this.state.formValues
      
        if(this.props.catData===null) return "Keine Kategorie zum auswählen vorhanden. Bitte wenden Sie sich an den Administrator"
        return (
            <Fade timeout={540} in={true} >
                <div className={classes.root}>
                    <section>
                        <Typography className={classes.title} variant='h5' color='primary'>
                            Neuen Beitrag erstellen
                        </Typography>
                    </section>
                    <form className={classes.form} noValidate autoComplete="off">
                        <FormControl variant="filled" className={classes.formControl}>
                            <InputLabel htmlFor="categorie">{selectedCat!=='' ? 'Kategorie' : 'Kategorie auswählen'}</InputLabel>
                            <Select
                                error={this.state.showErrorMsg && this.state.errors.selectedCat.length>0}
                                style={{width: '100%'}}
                                value={selectedCat}
                                onChange={(e)=>setStateByPropKey('selectedCat', e.target.value)}
                                input={<FilledInput name="categorie" id="categorie" />}
                            >
                                {this.props.catData.map(categorie => {
                                   
                                    return (
                                        <MenuItem key={categorie.id} value={categorie}>{categorie.title}</MenuItem>
                                    )
                                })}
                            </Select>
                            <FormHelperText  error={this.state.showErrorMsg && this.state.errors.selectedCat!==null}>{this.state.showErrorMsg && this.state.errors.selectedCat}</FormHelperText>
                        </FormControl>
                        <FormControl color='secondary' variant="filled" className={classes.formControl}>
                            <TextField
                               
                                color='secondary'
                                fullWidth
                                id="title"
                                error={this.state.showErrorMsg && this.state.errors.title.length>0}
                                label={"Titel - noch " + (40-this.state.formValues.title.length) + " Zeichen"} 
                                className={classes.textField}
                                onChange={e=>setStateByPropKey('title', e.target.value)}
                                margin="normal"
                                variant="filled"
                                placeholder='Hier den Titel eingeben'
                                helperText={this.state.showErrorMsg && this.state.errors.title}
                            />
                        </FormControl>
                        <FormControl variant="filled" className={classes.formControl}>
                            <TextField
                                id="text"
                                error={this.state.showErrorMsg && this.state.errors.text.length>0}
                                label={"Text - noch " + (400- this.state.formValues.text.length) + " Zeichen"}
                                placeholder="Geben Sie hier Ihren Text ein"
                                multiline
                                className={classes.textField}
                                margin="normal"
                                variant="filled"
                                onChange={e=>setStateByPropKey('text', e.target.value)}
                                fullWidth
                                helperText={this.state.showErrorMsg && this.state.errors.text}
                            />
                        </FormControl>
                        <div id='picWrapper' style={{textAlign: 'center'}}>
                            <div className={classes.picWrapper} style={{border: '2px solid blue'}}>
                                {this.state.loadingProgress!==100
                                    ? this.state.loadingStarts 
                                        ? <Spinner style={{marginTop: '2em'}} title={this.state.loadingProgress.toFixed(0) + ' % abgeschlossen'}/>
                                        : <div style={{minHeight: '1em', marginTop: '2em'}}>Bild hochladen</div>
                                    : <img style={{maxWidth: '100%', maxHeight:'100%'}} id='uploadImage' src={this.state.selectedImageURL} alt='Bild hochladen'  />      
                                }
                            </div>
                        </div>
                        <input
                            accept="image/*"
                            className={classes.input}
                            style={{ display: 'none' }}
                            id="raised-button-file"
                            //multiple
                            type="file"
                            onChange={e=>this.imageUploadHandler(e)}
                        />
                        <div style={{textAlign: 'center'}}>
                            <label htmlFor="raised-button-file">
                            <Button size='small' variant="contained" component="span" className={classes.picUploadButton}>
                                <CamRole /><span style={{margin: '4px'}}>{this.state.loadingProgress===100 ? 'Bild ändern' : 'Bild hinzufügen'}</span>
                            </Button>
                            </label>
                        </div>
                        <Button style={{marginTop: '8px'}} variant='contained' color='primary' onClick={this.saveForm} fullWidth>
                            Beitrag Speichern
                        </Button>
                    </form>
                </div>
            </Fade>
        )
    }
}

const mapStateToProps = state => ({
    catData: state.categories.data,
    user: state.user
})

export default compose(
    connect(mapStateToProps),
    withStyles(styles),
    withRouter,
    withSnackbar,
    )(AddPost)