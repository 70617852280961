import React from 'react'
import FeedItem from '../Components/Lists/MediaLists/ListItem'
import AddComment from './AddComment'
import Comments from './CommentList'

const FeedItemDetails = ({item, onCloseClick, index, isAdmin}) => {
    if(item===undefined) return 'NISCHT'
    return (
        <div>
            <FeedItem
                item={item}
                onItemClick={onCloseClick}
                index={index}
                expandedView={true}
                children={<CommentsContainer postId={item.id} 
                isAdmin={isAdmin}
                />}

                />
       
        </div>
    )
}

const CommentsContainer = ({postId}) => {
    return (
        <div>
            <AddComment postId={postId}/>
            <Comments postId={postId} />
        </div>
    )
}

export default FeedItemDetails
