import React from 'react'
import { CellMeasurer, CellMeasurerCache, List, AutoSizer } from "react-virtualized";
import ListItem from './ListItem'
import _ from 'lodash'
import Typography from '@material-ui/core/Typography'

const cache = new CellMeasurerCache({
    fixedWidth: true,
    defaultHeight: 500

})

const FeedList = (props) => {

 
    const { items, onItemClick, isAdmin, userId } = props

    const renderRow = ({ index, isScrolling, key, style, parent, isVisible }) => {
        const item = items[index]
        const data = item.data
        const commentsCounter = item.comments ? _.size(item.comments) : 0
    
        return(
            <CellMeasurer
                key={key}
                cache={cache}
                parent={parent}
                columnIndex={0}
                rowIndex={index}
          >
         
                <div
                    style={{...style, paddingBottom: '8px', paddingTop:'24px'}}
                >
                     <ListItem
                        item={data}
                        style={style}
                        onClick={onItemClick}
                        onItemClick={onItemClick}
                        index={index}
                        commentsCounter={commentsCounter}
                        expandedView={false}
                        isAdmin={isAdmin}
                        userId={userId}
                        />
                   
                </div>
                </CellMeasurer>
        )}
        if(items.length===0)return <div style={{paddingTop: '120px', textAlign: 'center'}}><Typography variant='h4'>Keine Posts zum anzeigen</Typography></div>
        
        return (
           
            <AutoSizer>
            {({height, width}) => (
         
                <List
                    scrollToIndex={props.activeItemIndex ? props.activeItemIndex : 0}
                    height={height}
                    width={width}
                  //  rowHeight={640}
                    rowRenderer={renderRow}
                    rowCount={items.length}
                    deferredMeasurementCache={cache}
                   rowHeight={cache.rowHeight}
                

                />
                 )}
        </AutoSizer>
        

        )
    
}

export default FeedList