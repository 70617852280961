import React, { Component } from 'react'
import TextField from '@material-ui/core/TextField'
import IconButton from '@material-ui/core/IconButton'
import Button from '@material-ui/core/Button'
import CameraIcon from '@material-ui/icons/CameraAlt'
import Typography from '@material-ui/core/Typography'
import { withStyles} from '@material-ui/core/styles'
import SaveIcon from '@material-ui/icons/Save'
import { picUpload, getPicURL } from 'firebase/storage/pictures'
import { getNewCatId, addCategorie } from 'firebase/database/categorieHandler'
import Avatar from '@material-ui/core/Avatar'
import Grid from '@material-ui/core/Grid'
import Divider from '@material-ui/core/Divider'
import { CircularProgress as Loading } from '@material-ui/core'
import { withSnackbar } from 'notistack'
import { compose } from 'recompose'

const styles = theme => ({
    root : {
       // backgroundColor: theme.palette.grey[100]
    },
    title: {
    
        marginRight: theme.spacing(2),
       
    },
    titleInput : {
        marginRight:  theme.spacing(2)
    },
    picUploadButton: {
      //  marginBottom: theme.spacing(2)
    },
    saveButton: {
        marginLeft: theme.spacing(2),
        backgroundColor: theme.palette.primary.main,
        color: 'white'
    }

})

const resetState = {
    title: '',
    selectedImageURL: null,
    selectedFile: null,
    newCatId: null,
    picURL: null,
    resPicURL: null,
    loadingProgress: 0,
    fileName: null,
    loadingStarts: false,

}
const resetDialog = {isOpen: false, title: '', text: '', buttons: []}

class AddCategorie  extends Component {
    constructor (props) {
        super(props)
        this.state = {
            title: '',
            selectedImageURL: null,
            selectedFile: null,
            newCatId: null,
            picURL:  null,
            resPicURL: null,
            loadingProgress: 0,
            fileName: null,
            loadingStarts: false,
          

        }
    }

    setStateByPropKey = (propKey, value) => {
       this.setState({
           [propKey]: value
       })

    }

    _noPicDialog = noPicConfirmed => {
        const { title, newCatId, } = this.state
        const id = newCatId

        if(noPicConfirmed) {
            this.props.handleDialog(resetDialog)
            return document.getElementById('addPicBtn').click()
        } else {
            const newCategorie = {
                title,
                picURL: false,
                resPicURL: false,
                fileName: false,
            }
            addCategorie(newCategorie, id)
            this.props.handleDialog(resetDialog)
            return this.setState(resetState)
        }
    }

    handleSaveCategorie = async () => {  
        const { fileName, picURL, title, newCatId } = this.state
        const id = newCatId
    
        // callback used by getPicURL to set resPicURL to state
        if(picURL===null) {
            this.props.handleDialog({
                isOpen: true,
                title: 'Kein Bild',
                text: "Sind Sie sicher das Sie kein Bild hinzufügen möchten?",
                buttons: [
                    <Button key={1} variant='outlined' color='secondary' onClick={()=>this._noPicDialog(true)}>Bild hinzufügen</Button>,
                    <Button key={2} variant='outlined' color='primary' onClick={()=>this._noPicDialog(false)}>Kein Bild verwenden</Button>,
                ]
            })
        } else {
   
        const picURLCallback = (resPicURL) =>this.setState({resPicURL})
        await getPicURL('resized-'+fileName, picURLCallback)

        const resPicURL = this.state.resPicURL
        const newCategorie = {
            title,
            picURL,
            resPicURL,
            fileName,
        }
        await addCategorie(newCategorie, id)
        
        this.setState(resetState)

        const variant = 'success'
        const message = 'Kategorie gespeichert'
        this.props.enqueueSnackbar(message, {
            variant,
            anchorOrigin: {
                vertical: 'bottom',
                horizontal: 'center',
            }, })
        
        }

    }
    
    imageUploadHandler = (e) => {
        
        e.preventDefault()
        const newCatId = getNewCatId()   
        const selectedFile = e.target.files[0]
        const orgFileName = selectedFile.name
        const fileExtension = orgFileName.toLowerCase().substring((orgFileName.length-4), orgFileName.length)
       // const fileExtentionsValidation = ['.jpg', '.png', '.bmp', '.tif']
       // const isValide = fileExtentionsValidation.includes(fileExtension)
        const fileName = 'catPic-'+newCatId+fileExtension
        // wrong file extention - inform user 
      //  if(!isValide)return alert('Sie müssen eine Bilddatei hochladen! (z.B. .jpg, .bmp, .tif, .png)')
        const selectedImageURL = URL.createObjectURL(selectedFile)
        this.setState({selectedImageURL, selectedFile, fileName, newCatId})
        picUpload(selectedFile, this.setStateByPropKey, fileName)
        this.setState({loadingStarts: true})
    } 

    render () {
        const {classes} = this.props

        return (
            <div className={classes.root}>
            <Grid container  alignItems="center" justify='space-between'>
            <Grid item md={4} >
            {this.state.loadingProgress===100
                ? <Avatar variant='square' src={this.state.selectedImageURL} alt='PIC' />
                : this.state.loadingStarts
                    ? <div style={{fontSize: '0.8em'}}><Loading color='primary' />{this.state.loadingProgress.toFixed(0)}%</div>
                    : <Typography className={classes.title} color='primary' variant='subtitle1'>Kategorie hinzufügen: </Typography>
            }
            </Grid>
            <Grid item md={4} >
             <TextField
                    fullWidth
                    className={classes.titleInput}
                    color='primary'
                    value={this.state.title}
                    id='catTitle'
                    name='catTitle'
                    placeholder="Titel"
                    onChange={e=>this.setStateByPropKey('title', e.target.value)}
                    //className={classes.input}
                    inputProps={{
                        'aria-label': 'Description',
                    }}
                    autoFocus
    
                />
             <input
                accept="image/*"
                className={classes.input}
                style={{ display: 'none' }}
                id="raised-button-file"
                //multiple
                type="file"
                onChange={e=>this.imageUploadHandler(e)}
            />
            </Grid>
            <Grid item md={4} >
            <div style={{textAlign: 'center', marginBottom: '8px'}}>
                <label htmlFor="raised-button-file">
           
                     <Button id='addPicBtn'  color='primary' size='small' variant="contained" component="span" className={classes.picUploadButton}>
                        <CameraIcon size='small'/><span style={{margin: '4px'}}>Bild</span>
                    </Button>
                </label>
                <IconButton disabled={this.state.title.length<4} onClick={this.handleSaveCategorie} className={classes.saveButton} variant='contained' component='span'><SaveIcon/></IconButton>
                </div>
            </Grid>
            </Grid>
            <Divider />
            </div>
        )
    }
}

export default compose(
    withStyles(styles),
    withSnackbar,
    )(AddCategorie)