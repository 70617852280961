import { db } from '../firebase'
import { dispatchUsers } from '../../store/actions/usersActions'
//import { getPostComments } from 'firebase/database/commentsHandler'
//import { logUsers } from './loggingHandler'
const usersRef = db.ref('/users')

export const watchUsers = (on) => {
    if(!on)return usersRef.off()
    return usersRef.on('value', async usersSanp => {
        const users = await usersSanp.val()
        return dispatchUsers(users)
    })
}

/*

export const deleteUser = async (user, userId, userName ) => { 
    if(user.myPosts){
        const { myPosts } = user
        myPosts.map( async postId => {
            const post = await getPost(postId)
            const picName = post.data.picName
            const resPicName = 'resized-'+picName
            if(post.comments) {
                const { comments } = post
                _.map(comments, async comment => {
                    const postComments = await getPostComments(postId)
                    _.map(postComments, postComment=> {
                        const commentUserId = postComment.userId
                        const commentId = postComment.id
                        db.ref('/users').child(commentUserId).child('myComments').child(commentId).remove()
                    })
                    const loggingData = {
                            email: user.profile.emai,
                            userId: user.id
                        }
                    logUsers('deleted/', loggingData)

                    return db.ref('/comments').child(comment.id).remove()   
                })

            }
            deletePic(picName)
            deletePic(resPicName)
            db.ref('/posts').child(postId).remove() 

        })
    }
        await usersDeleteRef.child(user.id).set(user)
        return usersRef.child(user.id).remove()
}

*/

export const deleteUser = user => {
    const userId = user.id
    return usersRef.child(userId).remove()
}

export const disableUser = user => {
    return db.ref('/temp/userDisabled').child(user.id).set(user)

}