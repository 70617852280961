  
  const applyActiveNavItem = (state, action) =>{
  
  return ({
    ...state,
    active: action.payload,
    navState: {
      ...state.navState,
      active: action.payload,
      activeTitle: 'Feed Liste',
    }


  });}
  
  function navigationReducer(state = {}, action) {
  
    switch(action.type) {
      case 'NAVIGATE_TO' : {
        return applyActiveNavItem(state, action);
      }
      default : return state;
    }
  }
  
  export default navigationReducer;