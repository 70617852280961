import React from 'react'
import { connect } from 'react-redux'
import Button from '@material-ui/core/Button'
import Paper from '@material-ui/core/Paper'
import { sendEmailVerification } from '../../firebase/auth'
import Typography from '@material-ui/core/Typography'
import { auth } from '../../firebase'
import { updateEmailVerification } from '../../firebase/database/userHandler'
import { deleteUser } from 'firebase/database/usersHandler'
import { useHistory } from "react-router-dom"
import { routes } from 'config/config'



const EmailVerificatonMessage = (props) => {
    
    setInterval(function(){
        auth.currentUser.reload()
        if(auth.currentUser.emailVerified ){
        updateEmailVerification(auth.currentUser.uid)
        window.location.reload()
        }
    }, 6000);

    const history = useHistory()
    const handleReRegister = () => {
        deleteUser({id: props.userId})
        return history.push(routes.public.USER_REGISTRATION)
    }

    
    return (
        <div>
            <Typography variant='h6' paragraph gutterBottom>
                Sie haben an Ihre Email Adresse einen Link erhalten.
            </Typography>
            <div style={{fontWeight: 'bold', fontSize: '1.6em', marginBottom: '16px'}}>{props.userEmail}</div>
            <Typography variant='h6' paragraph gutterBottom>
                Bitte besätigen Sie Ihre EMail Adresse mit klick auf dem Link in der Email.
            </Typography>
         
            <Button
                variant='contained'
                color='primary'
                onClick={sendEmailVerification}
                style={{marginBottom: '0.8em'}}
            >
                Link erneut senden
            </Button>
            <Paper style={{opacity: 0.8, padding: '0.8em'}}>
                <Typography variant='h6' style={{backgroundColor: 'white'}} gutterBottom>
                    Nicht Ihre E-Mail Adresse oder vertippt?
                </Typography>
                <Button
                    variant='contained'
                    color='secondary'
                    onClick={handleReRegister}

                >
                    Erneut Registrierung
                </Button>
            </Paper>
        </div>
    )
}

const mapStateToProps = state => ({
    userEmail: state.user.firebase.profile.email,
    userId: state.user.id
})

export default connect(mapStateToProps)(EmailVerificatonMessage)
