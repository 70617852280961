import _ from 'lodash'
import { actionTypes } from '../../config/config'
const INITIAL_STATE = {
    data: null,
    filtered: null,
    isLoading: true,
    counter: 0,
  }
  
  function postsReducer(state = INITIAL_STATE, action) {
    
      switch(action.type) {
        case actionTypes.FETCH_COMMENTS : {
            const comments = _.values(action.payload)
            const counter = comments.length
          return ({
              ...state,
              data: action.payload===null ? [] : _.sortBy(comments, 'timeStamp').reverse(),
              counter,
          })
        }      
        default : return state;
      }
    }
    
    export default postsReducer;