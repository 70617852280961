import React from 'react'
import TextField from '@material-ui/core/TextField'
import Button from '@material-ui/core/Button'
import { doCreateUserWithEmailAndPassword } from '../../firebase/auth'
import Paper from '@material-ui/core/Paper'
import Typography from '@material-ui/core/Typography'
import { withStyles } from '@material-ui/core/styles'
import { text } from '../../config/config'
//import _ from 'lodash'
import clsx from 'clsx';
//import { makeStyles } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import InputAdornment from '@material-ui/core/InputAdornment';
import FormHelperText from '@material-ui/core/FormHelperText';
import Checkbox from '@material-ui/core/Checkbox';
import FormControl from '@material-ui/core/FormControl';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import {Link} from 'react-router-dom'
import { routes } from 'config/config'

const styles = theme => {
    return ({
    title: {
        ...theme.typography.h5,
        color: theme.palette.primary.dark
    },
    messages: {
        ...theme.typography.h6,
        minHeight: '2em',
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2)
    },
    registrationFormWrapper: {
        padding: theme.spacing(2),
        marginBottom: theme.spacing(2)
    },
    registrationForm: {
        margin: '0 auto 0 auto',
        backgroundColor: 'white'
    },
    textField: {
        marginTop: theme.spacing(0)
    },
    submitButton: {
        marginTop: theme.spacing(3)
    },
    datenschutzWrapper: {
        marginTop: '8px'
    },
    datenschutzLink: {
        padding: '0 4px 0 4px'
    }

})}


class UserRegistration extends React.Component  {
    constructor(props) {
        super(props);
        this.emailInputRef = React.createRef()
        this.state = {
            formValues: {
                firstname: null,
                name: null,
                username: null,
                email: null,
                password: null,
                passwordConfirm: null,
                homeTown: null,
            },
            showErrorMsg: false,
            showPassword: false,
            createUserErrorMessage: null,
            errors :{
                ...text.userRegistration.errors,
            },
            dataProtectionConfirmed: false,
        }
      }
      
    setStateByPropKey = (propKey, value) => {    
        const isValide = this.inputValidation(propKey, value)
        if(isValide) {
            return this.setState({
                formValues: {
                    ...this.state.formValues,
                    [propKey]: value,
                },
                errors: {
                    ...this.state.errors,
                    [propKey]: null
                },
            })
        }
        this.setState({
            formValues: {
                ...this.state.formValues,
                [propKey]: null,
            },
            errors:{ ...this.state.errors, [propKey]: text.userRegistration.errors[propKey]}
        })
    }

    inputValidation = (propKey, value) => {
        //  if(value>3) return false
          let isValide = false
    switch (propKey) {
        case 'email':
            isValide = value.includes('@') && value.includes('.') && value.length>5 && !value.includes('&')
            break
        case 'username':
            isValide = value.length>5
            break
        case 'firstname':
            isValide = value.length>=3
            break
        case 'name' : 
            isValide = value.length>=3
                    break
        case 'homeTown' : 
            isValide = value.length>=1
            break
        case 'password' : 
            isValide = value.length>=6
            break
        case 'passwordConfirm' : 
            isValide = value===this.state.formValues.password
            break
        case 'dataProtectionConfirmed' : 
            isValide = value===true
            break
        default:
            return false
          }
          return isValide
        
    }

    handleSubmit = (e) => {
        e.preventDefault()
        const { email, username, firstname, name, homeTown, password, passwordConfirm } = this.state.formValues
        if(email && username && firstname && name && homeTown!==null && password!==null && password===passwordConfirm) {
            const errorCallback = error => {
                if(error.code==='auth/email-already-in-use') {
                    const errorMessage = ()=><span>Die E-Mail Adressse <strong>{this.state.email}</strong> wird bereits verwendet!</span>
                    this.setState({createUserErrorMessage: errorMessage()})
                    this.emailInputRef.current.focus()
                }
            }
            return doCreateUserWithEmailAndPassword(this.state.formValues, errorCallback)      
        }
        return this.setState({showErrorMsg: true})
    }
    variant = 'standard'
   
    render () {
        const { classes } = this.props
    
        return (
         
                <Paper className={classes.registrationFormWrapper}>
                    <Typography variant='h5' className={classes.title} >Benutzer Registrierung</Typography>
                    <Typography variant='h6' className={classes.messages}>
                      {this.state.createUserErrorMessage!==null
                        ? <ErrorMessage errorMessage={this.state.createUserErrorMessage} />
                        : <span>Geben Sie Ihre Daten ein um sich zu registrieren</span>}
                    </Typography>
                    <form className={classes.registrationFrom}  onSubmit={this.handleSubmit}>
                        <TextField
                            id="username"
                            label="Benutzername"
                            placeholder="Benutzername"
                            className={classes.textField}
                            error={this.state.showErrorMsg && this.state.errors.username!==null}
                            fullWidth
                            color='primary'
                            onChange={(event)=>this.setStateByPropKey('username', event.target.value)}
                            variant={this.variant}
                            helperText={this.state.showErrorMsg && this.state.errors.username}
                            //value={this.state.email}
                            //required
                        />
                        <TextField
                            id="email"
                            label="E-Mail Adresse"
                            placeholder="E-Mail Adresse"
                            className={classes.textField}
                            error={(this.state.showErrorMsg && this.state.errors.email!==null) || this.state.createUserErrorMessage!==null}
                            fullWidth
                            onChange={(event)=>this.setStateByPropKey('email', event.target.value)}
                            variant={this.variant}
                            type='email'
                            inputRef={this.emailInputRef}
                            //value={this.state.email}
                            helperText={this.state.showErrorMsg && this.state.errors.email}
                           // required
                        />
                        <TextField
                            id="firstname"
                            label="Vorname"
                            placeholder="Vorname"
                            className={classes.textField}
                            error={this.state.showErrorMsg && this.state.errors.firstname!==null}
                            fullWidth
                            onChange={(event)=>this.setStateByPropKey('firstname', event.target.value)}
                        // value={this.state.email}
                            variant={this.variant}
                            helperText={this.state.showErrorMsg && this.state.errors.firstname}
                          //  required
                        />
                        <TextField
                            id="name"
                            label="Name"
                            placeholder="Name"
                            className={classes.textField}
                            error={this.state.showErrorMsg && this.state.errors.name!==null}
                            fullWidth
                            onChange={(event)=>this.setStateByPropKey('name', event.target.value)}
                            //value={this.state.email}
                            variant={this.variant}
                            helperText={this.state.showErrorMsg && this.state.errors.name}
                            //required
                        />
                          <TextField
                            id="homeTown"
                            label="Wohnort"
                            placeholder="Wohnort"
                            className={classes.textField}
                            error={this.state.showErrorMsg && this.state.errors.homeTown!==null}
                            fullWidth
                            onChange={(event)=>this.setStateByPropKey('homeTown', event.target.value)}
                            //value={this.state.email}
                            variant={this.variant}
                            helperText={this.state.showErrorMsg && this.state.errors.homeTown}
                            //required
                        />
                        <FormControl
                            fullWidth
                            className={clsx(classes.margin,classes.textField)}
                            error={this.state.showErrorMsg && this.state.errors.password!==null}
                        >
                            <InputLabel htmlFor="standard-adornment-password">Password</InputLabel>
                            <Input
                                id="standard-adornment-password"
                                type={this.state.showPassword ? 'text' : 'password'}
                                //required
                                onChange={e=>this.setStateByPropKey('password', e.target.value)}
                                endAdornment={
                                <InputAdornment position="end" >
                                    <IconButton
                                     disableFocusRipple
                                    aria-label="toggle password visibility"
                                    onClick={()=>this.setState({showPassword: !this.state.showPassword})}
                                    //onMouseDown={handleMouseDownPassword}
                                    >
                                    {this.state.showPassword ? <Visibility /> : <VisibilityOff />}
                                    </IconButton>
                                </InputAdornment>
                                }
                            />
                            {this.state.showErrorMsg && <FormHelperText>{this.state.errors.password}</FormHelperText>}
                            </FormControl>
                        <FormControl
                            //required
                            fullWidth
                            className={clsx(classes.margin, classes.textField)}
                            error={this.state.showErrorMsg && this.state.errors.passwordConfirm!==null}
                        >
                            <InputLabel htmlFor="standard-adornment-passwordConfirm">Password</InputLabel>
                            <Input
                                id="standard-adornment-passwordConfirm"
                                type={this.state.showPassword ? 'text' : 'password'}
                                onChange={e=>this.setStateByPropKey('passwordConfirm', e.target.value)}
                                endAdornment={
                                <InputAdornment position="end">
                                    <IconButton
                                    disableFocusRipple
                                    aria-label="toggle password visibility"
                                    onClick={()=>this.setState({showPassword: !this.state.showPassword})}
                                    //onMouseDown={handleMouseDownPassword}
                                    >
                                    {this.state.showPassword ? <Visibility /> : <VisibilityOff />}
                                    </IconButton>
                                </InputAdornment>
                                }
                            />
                                {(this.state.showErrorMsg && this.state.errors.passwordConfirm!==null) && <FormHelperText>{this.state.errors.passwordConfirm}</FormHelperText>} 
                            </FormControl>
                        <div className={classes.datenschutzWrapper}>
                            <Checkbox required onChange={()=>this.setState({'dataProtectionConfirmed': !this.state.dataProtectionConfirmed})} color='primary'/> 
                            <Typography component='span' variant='h6'>
                                Ich erläre mich mit den
                            </Typography>
                                <Link className={classes.datenschutzLink} to={routes.public.DATA_PROTECTION}>
                                <Typography component='div' variant='h6'>
                                    Datenschutzrichtlinen
                                </Typography>
                                </Link>
                            <Typography component='span' variant='h6'>
                                einverstanden
                            </Typography>
                        </div>
                        <Button
                            className={classes.submitButton}
                            type='submit'
                            color='primary'
                            fullWidth
                            variant='contained'
                            disabled={!this.state.dataProtectionConfirmed}
                        >
                            Registrierung abschließen
                        </Button>
                    </form>
                </Paper>
        )
    }
}

const ErrorMessage = ({errorMessage}) => <div style={{color: 'red'}} >{errorMessage}</div>

export default withStyles(styles)(UserRegistration)