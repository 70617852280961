import React from 'react'
import { withStyles } from '@material-ui/core/styles';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpansionPanelActions from '@material-ui/core/ExpansionPanelActions';

import Divider from '@material-ui/core/Divider';


const styles = theme => ({
  root: {
    width: '100%',
  
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightBold,
  },
  expPanel: {
    backgroundColor: theme.palette.grey['100']
  }
});

const ExpensionPanel = props => {
  
    const { classes, title, subtitle, children, actions, onExpand } = props
 
    return (
        <div className={classes.root}>
          <ExpansionPanel expanded={props.expanded} onChange={onExpand}>
            <ExpansionPanelSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1bh-content"
              id="panel1bh-header"
              className={classes.expPanel}
            >
              <Typography color='primary' className={classes.heading}>{title || null}</Typography>
              {subtitle && <Typography className={classes.secondaryHeading}>{subtitle}</Typography>}
            </ExpansionPanelSummary>
            <ExpansionPanelDetails>
              {children || <span>Kein Inhalt</span>}
            </ExpansionPanelDetails>
             <Divider />
             {actions && <ExpansionPanelActions>
              {actions.map(action =>action)}
            </ExpansionPanelActions>}
          </ExpansionPanel>
          
        </div>
    )
}

export default withStyles(styles)(ExpensionPanel)