import React, { Component } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography'
import Paper from '@material-ui/core/Paper'
import { useHistory } from 'react-router-dom'
import  { routes, text } from '../../config/config'
import { doSignInWithEmailAndPassword } from '../../firebase/auth'
import LeaderLogos from 'Components/Leader/LeaderLogos'

const useStyles = makeStyles(theme => ({
    paper: {
      backgroundColor: 'white',
      padding: '16px',
      borderRadius: '8px'
    },
    headlineWrapper: {
      marginBottom: theme.spacing(3)
    }
  }))



const SignInPage = () => {
  const classes = useStyles()
  const history = useHistory()
    return(
    <div style={{textAlign: 'center'}}>
    <LeaderLogos />
    <Paper className={classes.paper} >
      <section className={classes.headlineWrapper}>
        <Typography color='primary' variant='h5' children='Anmelden' gutterBottom/>
        <Typography variant='h6' >Geben Sie ihre Anmeldedaten ein</Typography>
      </section>
      <SignInForm  />
      <Button 
        size='small'
        color='primary'
        //variant='outlined'
        onClick={()=>history.push(routes.public.PASSWORD_FORGET)}
      >
        Kennwort vergessen
      </Button>
      
      <Button
        style={{marginLeft: '1em'}}
        size='small'
        color='primary'
        //variant='outlined'
        onClick={()=>history.push(routes.public.USER_REGISTRATION)}
      >
        Registrieren
      </Button>
    </Paper>
  </div>)}

const byPropKey = (propertyName, value) => () => ({
  [propertyName]: value,
});


export class SignInForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      email: '',
      password: '',
      error: null,
     };
  }

  onSubmit = async (event) => {
    event.preventDefault();
    const {
      email,
      password,
    } = this.state;
      const error = await doSignInWithEmailAndPassword(email, password)
      if(error.user) return this.setState(byPropKey('error', null));
      this.setState(byPropKey('error', error));
  }

  _renderErrorMessage = (error) => {
      return text.login.errors[error] || text.login.errors.default
  }

  render() {
    const {
      email,
      password,
      error,
    } = this.state;

  const isInvalid = password.length<4 || email.indexOf('@')<2

    return (   
        <form onSubmit={this.onSubmit}>
          <TextField
            color='primary'
            value={email}
            onChange={event => this.setState(byPropKey('email', event.target.value))}
            type="text"
            placeholder="Email Adresse"
            autoFocus
            fullWidth
          />
          <br />
          <TextField
            style={{marginTop: '1em'}}
            value={password}
            onChange={event => this.setState(byPropKey('password', event.target.value))}
            type="password"
            placeholder="Passwort"
            color= 'primary'
            fullWidth
          />
          <br />
    
          <Button
            fullWidth
            style={{margin: '1em 0 1em 0'}}
            variant="contained"
            color={this.state.error!==null ? "secondary" : "primary"}
            disabled={isInvalid} type="submit">
            Anmelden
          </Button>

          { error && <div style={{marginTop: '8px', color: 'red'}}>{this._renderErrorMessage(error)}</div>}
        </form>
    );
  }
}

export default SignInPage
