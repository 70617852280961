import { db } from '../firebase'
import { dispatchAuthUser } from '../../store/actions/userActions'
import moment from 'moment'
const usersRef = db.ref('/users')

export const setUserConnectionState = (userId, conState) => {
        const userConRef = db.ref('/usersOnline/').child(userId)
        userConRef.set({[conState]: moment().format()})
}

export const fetchUser = async (userId, emailVerified, email) => {
    if(userId===false || userId===undefined || userId===null) return dispatchAuthUser(false)
    const userSnap = await usersRef.child(userId).once('value')
    if(userSnap.exists()) {
        const user = await userSnap.val()
        user.emailVerified = emailVerified
        return dispatchAuthUser(user)
    }
    console.error('System User has no fb Profile')
    const user = {
        id: userId,
        emailVerified: true,
        profile: {
            username: 'NN',
            firstname: 'NN',
            name: 'NN',
            email: email
        },
        role: {
            admin: false,
            appUser: true,
            user: true,
        }
    }
    return dispatchAuthUser(user)
   
}

export const createUserProfile = (userId, profile) => {
    const userData = {
        id: userId,
        emailVerified: false,
        appUser: true,
        profile: profile,
        role: {
            admin: false,
            appUser: true,
            user: false,
        }
    }
   return usersRef.child(userId).set(userData)
}


export const updateEmailVerification = (userId) => {
    usersRef.child(userId).child('emailVerified').set(true)
}