import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { addComment } from 'firebase/database/commentsHandler'
import TextFiled from '@material-ui/core/TextField'
import Button from '@material-ui/core/Button'
import Card from '@material-ui/core/Card'
import { connect } from 'react-redux'
import SendIcon from '@material-ui/icons/Send'
const useStyles = makeStyles(theme => {

    return ({
        container: {
            display: 'flex',
            flexDirection: 'row',
            width: '100%',   
          //  minHeight: '80px',
         //   borderTop: '2px solid ' + theme.palette.primary.light,
          //  borderBottom: '2px solid ' + theme.palette.primary.light,
           // padding: theme.spacing(2),
          //  justifyContent: 'center',
            
        },
        textFieldWrapper: {
            display: 'flex-item',
            width: '69%',
            padding: theme.spacing(2)
        },
        textFiled: {
       
        },
        buttonWrapper: {
            padding: theme.spacing(2),
            display: 'flex-item',
            textAlign: 'right',
            marginLeft: theme.spacing(1),
            width: '39%'
           

        }
    })
})

const AddComment = ({userName, userId, postId}) => {
    const classes = useStyles()
    const [isValide, setIsValide ] = React.useState(false)
    const [comment, setComment] = React.useState('');
    
    const handleAddComment = () => {
   
        addComment(userId, userName, postId, comment)
        setComment('')
        return setIsValide(false)

    }
    const handleCommentInput = (event) => {
        const value = event.target.value
      
        if(value.length>=3) {
            setIsValide(true) 
        } else {
            setIsValide(false)
        }
        setComment(value)
    }

    return (
        <Card style={{padding: '16px'}}>
            <form className={classes.container} >
                    <TextFiled
                        fullWidth
                        className={classes.textFiled}
                        id='comment'
                        name='comment'
                        label='Neue Kommentar'
                        placeholder='Geben Sie hier Ihren Kommentar ein'
                        onChange={e=>handleCommentInput(e)}
                        multiline
                       value={comment}
                 
                    />
              
                    <Button
                        color='primary'
                        variant='contained'
                        disabled={!isValide}
                        size='large'
                        onClick={handleAddComment}
                    ><SendIcon /></Button>
       
            </form>
        </Card>
    )
}

const mapStateToProps = state => ({
    userId: state.user.id,
    userName: state.user.firebase.profile.username
})

export default connect(mapStateToProps)(AddComment)
