import React from 'react'
import eu_flagge from 'static/leader/eu_flagge.png'
import leader_neu from 'static/leader/leader_neu.png'
import wappen_4c from 'static/leader/wappen_4c.png'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import { makeStyles } from '@material-ui/core/styles'
//import Paper from '@material-ui/core/Paper'
import senLogo from 'static/leader/seniorengemeinschaft.png'
import genbundLogo from 'static/leader/generationenbund-bgl.png'
const leaderText = 'Gefördert durch das Bayerische Staatsministerium für Ernährung, Landwirtschaft und Forsten und den Europäischen Landwirtschaftsfonds für die Entwicklung des ländlichen Raums (ELER)'

const useStyles = makeStyles(theme=>  {
    console.log(theme)
    return ({
        text: {
            fontSize: '0.6rem', 
            [theme.breakpoints.down("sm")]: {
                fontSize: '0.2rem',
            }
        },
        genbundLogo: {
            width: '83%',
            [theme.breakpoints.down("sm")]: {
                width: '100%',
            }
        }

    })

})

const LeaderBoard = () => {

    const classes = useStyles()
    console.log(classes)
    return (
     
        <Grid container spacing={0} style={{padding: '4px'}} >
            <Grid item xs={3} md={2} style={{}}>
                <div style={{backgroundColor: 'white'}}>
                    <img className={classes.genbundLogo} src={genbundLogo} alt='generationenbund bgl' />
                </div>
            </Grid>
           <Grid item xs={2} md={1}  >
                <img src={senLogo} alt='seniorengemeinschaft' style={{width: '65%', textAlign: 'right', paddingLeft: '4px'}}/>
            </Grid>
            <Grid item xs={2} md={1} >
                <img src={eu_flagge} alt='EU Flagge' style={{width: '94%'}} />
            </Grid>
            <Grid item xs={2} md={1} style={{textAlign: 'center'}} >
                <img src={wappen_4c} alt='Wappen' style={{width: '100%'}} />
            </Grid>
            <Grid item xs={2} md={1} >
                <img src={leader_neu}alt='Leader' style={{width: '63%', paddingLeft: '4px'}} />
            </Grid>
            <Grid  item xs={12} md={7} style={{textAlign: 'left', paddingRight: '20px'}}>
  
                <Typography className={classes.text} children={leaderText} variant='subtitle1' style={{textAlign: 'left'}}  />
            </Grid>
        </Grid>
    
    )
}

export default LeaderBoard
