
import React from 'react'

const text =  {
  /* landingPage: {
        text: 'TEST'
    }, */
}

export const userAccountTxt = {
  dialog: {
    resetPassword: (email)=>({
    title: <span style={{fontWeight: 'bold'}}>Kennwort zurücksezten?</span>,
    text: <span>Sie erhalten eine Email an <span style={{fontWeight: 'bold'}}>{email || null}</span>. Mit Klick auf den Link können Sie ein neues Kennwort vergeben</span>
    }),
    deleteUserAccount: (username)=>({
      title: <span>Benutzerkonto <span style={{fontWeight: 'bold'}}>{username || null}</span> löschen?</span>,
      text: <span>Ihre Benutzerdaten werden gelöscht. Sie können sich nicht mehr an der App anmelden.</span>
    })
  },
}

export default text