import { privateRoutes, publicRoutes, adminRoutes  } from './routes'
import RestoreIcon from '@material-ui/icons/Restore'
import FavoriteIcon from '@material-ui/icons/Favorite'
import LocationOnIcon from '@material-ui/icons/LocationOn'
import AccountIcon from '@material-ui/icons/AccountBox'



export const mainNavigation = [
    {
        title: 'Beiträge',
        icon: RestoreIcon,
        path: privateRoutes.PUBLIC_FEED
    },
    {
        title: 'Beitrag erstellen',
        icon: FavoriteIcon,
        path: privateRoutes.ADD_POST
    },
    {
        title: 'Benutzer',
        icon: AccountIcon,
        path: privateRoutes.USER_ACCOUNT
    },
]

export const adminNavigation = [
    ...mainNavigation,
    {
        title: 'Divider'
    },
    {
        title: 'Admin Menü',
        icon: RestoreIcon,
        path: adminRoutes.ADMIN_HOME,

    }
]

export const nonAuthNavigation = [
    {
        title: 'Login',
        icon: FavoriteIcon,
        path: publicRoutes.LOGIN,

    },
    {
        title: 'Registrieren',
        icon: LocationOnIcon,
        path: publicRoutes.USER_REGISTRATION,
    },
    {
        title: 'Startseite',
        icon: LocationOnIcon,
        path: publicRoutes.LANDING_PAGE ,

    }
]

export const getRoleBasedNavigation = (role) => {
  
    if(role===null) return nonAuthNavigation
    if(role.admin)return adminNavigation
    if(role.appUser)return mainNavigation
   
    return nonAuthNavigation
}

export default {
    getRoleBasedNavigation
}