import React from 'react'
import UserMgm from './UserMgm'
import Tabs from '@material-ui/core/Tabs'
import Tab from '@material-ui/core/Tab'
import { makeStyles } from '@material-ui/core/styles'
import Slide from '@material-ui/core/Slide'
import CategorieMgm from './CategorieMgm'
import UserAccountsIcon from '@material-ui/icons/PeopleAlt'
import CategoriesIcon from '@material-ui/icons/PermMedia'


const useStyles = makeStyles(theme => {

    return ({
    headContainer: {
        borderRadius: '8px',
        backgroundColor: theme.palette.primary.main,
        [theme.breakpoints.down('sm')]: {
            borderRadius: 0,
          },
    },
     content: {
         paddingTop: theme.spacing(2)
     },
     tabsRoot: {
       
       
    },
     tabRoot: {
         fontSize: '0.8em',
         fontFamily: [
            '-apple-system',
            'BlinkMacSystemFont',
            '"Segoe UI"',
            'Roboto',
            '"Helvetica Neue"',
            'Arial',
            'sans-serif',
            '"Apple Color Emoji"',
            '"Segoe UI Emoji"',
            '"Segoe UI Symbol"',
          ].join(','),
          '&:hover': {
            color: '#40a9ff',
            opacity: 1,
          },
          '&$tabSelected': {
            color: '#1890ff',
            fontWeight: theme.typography.fontWeightMedium,
          },
          '&:focus': {
            color: '#40a9ff',
          },
     },
     tabSelected: {
        backgroundColor: theme.palette.primary.dark,
     
    },
    })

})
export default function AdminHome() {
    const classes = useStyles()
    const [value, setValue] = React.useState(1);
  
    const handleChange = (event, newValue) => {
      setValue(newValue);
    }
    
    return (
        <div>
            <section className={classes.headContainer}>
                <Tabs
                    className={classes.tabsRoot}
                    centered
                    value={value}
                    indicatorColor="secondary"
                    textColor="secondary"
                    onChange={handleChange}
                    aria-label="disabled tabs example"
                >
                    <Tab classes={{ root: classes.tabRoot, selected: classes.tabSelected }} icon={<UserAccountsIcon/>} label='Benutzer'/>
                    <Tab classes={{ root: classes.tabRoot, selected: classes.tabSelected }} icon={<CategoriesIcon/>} label='Kategorien'/>
                </Tabs>
            </section>
            <section className={classes.content}>
                <Slide direction="right" in={value===0} mountOnEnter unmountOnExit>
                    <div>
                        <UserMgm />
                    </div>
                </Slide>
                <Slide direction="left" in={value===1} mountOnEnter unmountOnExit>
                    <div>
                        <CategorieMgm />
                    </div>
                </Slide>
            </section>
        </div>
    );
  }
