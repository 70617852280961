import { dispatch } from 'store/'

const fetchCategories = (categories) => {
    return dispatch({type: 'FETCH_CATEGORIES', payload: categories} )
}

const removeCategorie = categorie => {
    return dispatch({type: 'REMOVE_CATEGORIE', payload: categorie})
}

const addCategorie = categorie => {
    return dispatch({type: 'ADD_CATEGORIE', payload: categorie})
}

const fetchUserCategories = userCategories =>{
    dispatch({type: 'FETCH_USER_CATEGORIES', payload: userCategories})}


export {
    fetchCategories,
    removeCategorie,
    addCategorie,
    fetchUserCategories,
}
