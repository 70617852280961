import React, { useState } from 'react'
import { connect } from 'react-redux'
import { withStyles } from '@material-ui/core'
import { compose } from 'recompose'
import { Typography, TextField, Button } from '@material-ui/core'
import { sendPasswordResetEmail, doSignOut } from 'firebase/auth/index'
import { deleteUser } from 'firebase/database/usersHandler'
import { withSnackbar } from 'notistack'
import Dialog from 'Components/PopUps/Dialog'
import { userAccountTxt } from 'config/text'

const styles = theme => ({
    root: {
        padding: theme.spacing(1)
    },
    title: {
        textAlign: 'center'
    },
    inputField: {
        marginTop: theme.spacing(2)
    },
    button: {
        marginTop: theme.spacing(2)
    }
})

const initDialogState = {
    isOpen: false,
    text: '',
    title: '',
    buttons: []
}

const UserProfile = props => {
    
    const { classes, user, enqueueSnackbar } = props
    const [ passwordResetError, setPasswordResetError ] = useState(false)
    const [ dialog, setDialog ] = useState({isOpen: false, text: '', title: '', buttons: []})
    
    const handlePasswordReset = email => {
        const resetCallback = error => {
            return setPasswordResetError(error)
        }
        const handlePasswordResetConfirmed = (resetState) => {
            if(resetState) {
                const message = 'Link zum zurücksetzten des Kennworts wurde versendet'
                const variant = 'success'
                enqueueSnackbar(message, {
                    variant,
                    anchorOrigin: {
                        vertical: 'bottom',
                        horizontal: 'center',
                    }, })
                sendPasswordResetEmail(user.firebase.profile.email, resetCallback)
                return setDialog(initDialogState)
            } else {
                return setDialog(initDialogState)
            }
        }
        const passwordResetButtons = [
            <Button key={1} onClick={()=>handlePasswordResetConfirmed(true)} variant='contained' color='primary' >Reset Link senden</Button>,
            <Button key={2} onClick={()=>handlePasswordResetConfirmed(false)} variant='outlined' color='primary' >Abbrechen</Button>,
        ]

        setDialog({isOpen: true, ...userAccountTxt.dialog.resetPassword(user.firebase.profile.email), buttons: passwordResetButtons})

    }
    const handleAccountDelete = () => {

        const handleDialog = delState => {
            if(!delState) {
                return setDialog(initDialogState)
            } else {
                deleteUser(props.user.firebase)
                return doSignOut()
            }

        }

        const accountDeleteButtons = [
            <Button key={1} variant='contained' color='primary' onClick={()=>handleDialog(true)} >Benutzerkonto löschen</Button>,
            <Button key={2} variant='outlined' color='primary' onClick={()=>handleDialog(false)} >Abbrechen</Button>,
        ]
        setDialog({isOpen: true, ...userAccountTxt.dialog.deleteUserAccount(user.firebase.profile.username), buttons: accountDeleteButtons})
        
    }
  
        return (
            <div className={classes.root}>
                <Typography className={classes.title} variant='h5' color='primary'>
                        Benutzer Konto
                </Typography>
                {passwordResetError}
                <section className={classes.body}>
                    <TextField
                        className={classes.inputField}
                        readOnly
                        autoFocus={false}
                        fullWidth
                        size='small'
                        label='Benutzername'
                        value={user.firebase.profile.username}
                        color='primary'
                    />
                     <TextField
                        className={classes.inputField}
                        readOnly
                        fullWidth
                        size='small'
                        label='E-Mail'
                        value={user.firebase.profile.email}
                        color='primary'
                    />
                    <TextField
                        className={classes.inputField}
                        fullWidth
                        readOnly
                        size='small'
                        label='Vorname'
                        value={user.firebase.profile.firstname}
                        color='primary'
                    />
                    <TextField
                        className={classes.inputField}
                        fullWidth   
                        readOnly
                        size='small'
                        label='Nachname'
                        value={user.firebase.profile.name}
                        color='primary'
                    />
                    <div className={classes.buttonBoard} >
                        <Button
                            className={classes.button}
                            fullWidth
                            color='primary'
                            variant='contained'
                            onClick={doSignOut}
                        >
                            Abmelden
                        </Button>
                        <Button
                            className={classes.button}
                            fullWidth
                            color='primary'
                            variant='outlined'
                            onClick={handlePasswordReset}
                        >
                            Kennwort zurücksetzen
                        </Button>
                        <Button
                            className={classes.button}
                            fullWidth
                            color='primary'
                            variant='outlined'
                            onClick={handleAccountDelete}
                        >
                            Benutzer Konto löschen
                        </Button>
                    </div>
                        
                </section>
                {dialog.isOpen && <Dialog data={dialog} />}
            </div>
        )
}

const mapStateToProps = state => ({
    user: state.user
})

export default compose(
    withStyles(styles),
    connect(mapStateToProps),
    withSnackbar,

)(UserProfile)