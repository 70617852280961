
import firebase from 'firebase/app'
import 'firebase/auth'
import 'firebase/database'
import 'firebase/storage'
import firebaseConfig from '../config/firebase.cfg'


firebase.initializeApp(firebaseConfig.DEV);
firebase.auth().languageCode = 'de'
/*
const email = 'prinz2@mail.de'
const password='susi.123'
  // Get a reference to the database service
  */
 const db = firebase.database()
 const auth = firebase.auth()
 const storage = firebase.storage()


export {
  db, auth,
  storage
}
export default firebase


