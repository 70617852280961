import { actionTypes } from '../../config'
import { values } from 'lodash'    
    function usersReducer(state = {}, action) {
    
      switch(action.type) {
       
        case actionTypes.DISPATCH_USERS : {
          return ({
              ...state,
              data: action.payload===null ? [] : values(action.payload)
          })
      }
      
        default : return state;
      }
    }
    
    export default usersReducer;